import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { faRoadBarrier } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoleCode } from 'corso-types';
import AuxiliaryLayout from './layouts/AuxiliaryLayout';
import ClaimsLayout from './layouts/ClaimsLayout';
import SignUpLoader from './loaders/SignUpLoader';
import UserWelcomeLoader from './loaders/UserWelcomeLoader';

import AppLayout from './layouts/AppLayout';
import RegistrationsLayout from './layouts/RegistrationsLayout';
import SettingsLayout, { queryParamKey } from './layouts/SettingsLayout';
import ShippingClaimsLayout from './layouts/ShippingClaimsLayout';
import CorsoAI from './pages/analytics/CorsoAI';
import Dashboards from './pages/analytics/Dashboards';
import ClaimReview from './pages/claims/ClaimReview';
import DefaultStoreNavigation from './pages/DefaultStoreNavigation';
import Invoices from './pages/Invoices';
import NotFound from './pages/NotFound';
import OrderOverview from './pages/orders/OrderOverview';
import RegistrationOverview from './pages/registrations/RegistrationsOverview';
import ClaimReasonSettings from './pages/settings/ClaimReasonSettings';
import CustomFieldSettings from './pages/settings/CustomFieldSettings';
import EmailSettings from './pages/settings/EmailSettings';
import IntegrationsSettings from './pages/settings/IntegrationsSettings';
import NotificationsSettings from './pages/settings/NotificationsSettings';
import ProductGroupSettings from './pages/settings/ProductGroupSettings';
import RegistrationSettings from './pages/settings/RegistrationSettings';
import ReturnsSettings from './pages/settings/ReturnsSettings';
import ShippingPolicySettings from './pages/settings/ShippingPolicySettings';
import ShippingProtectionSettings from './pages/settings/ShippingProtectionSettings';
import StoreRuleForm from './pages/settings/storeRules/StoreRuleForm';
import StoreRulesList from './pages/settings/storeRules/StoreRulesList';
import StoreRulesOverview from './pages/settings/storeRules/StoreRulesOverview';
import StoreRuleTemplates from './pages/settings/storeRules/StoreRuleTemplates';
import ThemeSettings from './pages/settings/ThemeSettings';
import UserSettings from './pages/settings/UserSettings';
import WarrantiesSettings from './pages/settings/WarrantiesSettings';
import ShippingClaimOverview from './pages/shippingClaims/ShippingClaimOverview';
import SignOut from './pages/SignOut';
import SignUp from './pages/SignUp';
import UnhandledError from './pages/UnhandledError';
import UserWelcome from './pages/UserWelcome';
import Auth0ProviderWithNavigate from './providers/Auth0ProviderWithNavigate';
import { AuthenticationProvider } from './providers/AuthenticationProvider';
import {
  MerchantProvider,
  useMerchantContext,
} from './providers/MerchantProvider';
import { SettingsActionsProvider } from './providers/SettingsActionsProvider';

const roleCodeName = {
  [RoleCode.admin]: 'Admin',
  [RoleCode.staff]: 'Staff',
  [RoleCode.commerceApiAdmin]: 'Commerce API Admin',
} satisfies Record<`${Exclude<RoleCode, RoleCode.corsoAdmin>}`, string>;

/**
 * Protects a route given the `allowedRoles`, if the user's role is not in the list, they will be shown a message; otherwise, render the `Outlet` to delegate to further routes.
 */
function RouteProtector({
  allowedRoles,
}: {
  allowedRoles: `${Exclude<RoleCode, RoleCode.corsoAdmin>}`[];
}) {
  const { storeUser } = useMerchantContext();
  const { roleCode } = storeUser;
  if (roleCode !== RoleCode.corsoAdmin && !allowedRoles.includes(roleCode)) {
    // an alternative would be to redirect home
    return (
      <section className="flex flex-col items-center justify-evenly gap-2 md:flex-row">
        <FontAwesomeIcon
          icon={faRoadBarrier}
          className="size-56 text-neutral-300"
        />
        <div className="p-2">
          <strong className="text-pretty text-3xl font-bold leading-normal">
            You Don&apos;t Have Access
          </strong>
          <p>
            {allowedRoles.length === 1 ?
              'The following role'
            : 'One of the following roles'}{' '}
            is required to access this page:{' '}
            <span className="font-bold">
              {allowedRoles
                .map((allowedRole) => roleCodeName[allowedRole])
                .join(', ')}
            </span>
            .
          </p>
        </div>
      </section>
    );
  }
  return <Outlet />;
}

/** Route which is protected for admins only. */
function AdminProtector() {
  return <RouteProtector allowedRoles={[RoleCode.admin]} />;
}

function AuthProviders() {
  return (
    <Auth0ProviderWithNavigate>
      <AuthenticationProvider>
        <Outlet />
      </AuthenticationProvider>
    </Auth0ProviderWithNavigate>
  );
}

function AppProviders() {
  return (
    <MerchantProvider>
      <SettingsActionsProvider>
        <Outlet />
      </SettingsActionsProvider>
    </MerchantProvider>
  );
}

// ! verify src/pages/DefaultStoreNavigation.tsx routes when making changes to this file

// TODO add error boundaries to help with error handling, especially when a new version is deployed
export const routes = createRoutesFromElements(
  <Route path="/" errorElement={<UnhandledError />}>
    <Route element={<AuthProviders />}>
      <Route path="sign-out" element={<SignOut />} />
      <Route element={<AppProviders />}>
        <Route path="/:storeId?" element={<AppLayout />}>
          {/* // ? is there some way to name/identify a route so that links can always go to it; i.e. making this a home without needing to always specify the `storeId`, maybe instead `navigate` with a basename */}
          <Route index element={<DefaultStoreNavigation />} />
          {/* // * currently omitting dashboard at `/` until we know better what we want to display to merchants */}
          <Route path="registrations" element={<RegistrationsLayout />}>
            <Route
              path=":registrationId?"
              index
              element={<RegistrationOverview />}
            />
          </Route>

          <Route path="orders/lookup" element={<OrderOverview />}>
            <Route path=":orderIdFromPlatform?" element={<OrderOverview />} />
          </Route>

          <Route path="claims">
            {/* // * redirect to default route if no claim type selected */}
            <Route
              index
              element={<Navigate to=".." relative="path" replace />}
            />

            <Route path="shipping" element={<ShippingClaimsLayout />}>
              <Route
                path=":shippingClaimId?"
                index
                element={<ShippingClaimOverview />}
              />
            </Route>

            <Route path=":claimType" element={<ClaimsLayout />}>
              <Route path=":claimId?" index element={<ClaimReview />} />
            </Route>
          </Route>

          <Route path="analytics" element={<AdminProtector />}>
            <Route index element={<Navigate to="dashboards" replace />} />
            <Route path="dashboards" element={<Dashboards />} />
            <Route path="ai" element={<CorsoAI />} />
          </Route>
          <Route path="billing" element={<AdminProtector />}>
            <Route index element={<Invoices />} />
          </Route>

          <Route path="settings" element={<SettingsLayout />}>
            <Route element={<AdminProtector />}>
              <Route
                index
                element={
                  <Navigate
                    to={{
                      pathname: 'theme',
                      search: new URLSearchParams({
                        [queryParamKey.settingsNavigation]: 'true',
                      }).toString(),
                    }}
                    replace
                  />
                }
              />
              <Route path="theme" element={<ThemeSettings />} />
              <Route path="email" element={<EmailSettings />} />
              <Route path="integrations" element={<IntegrationsSettings />} />
              <Route path="returns" element={<ReturnsSettings />} />
              <Route path="warranties" element={<WarrantiesSettings />} />
              <Route path="registrations" element={<RegistrationSettings />} />
              <Route path="reasons" element={<ClaimReasonSettings />} />
              <Route path="product-groups" element={<ProductGroupSettings />} />
              <Route path="notifications" element={<NotificationsSettings />} />
              <Route path="custom-fields" element={<CustomFieldSettings />} />
              <Route
                path="shipping-protection"
                element={<ShippingProtectionSettings />}
              />

              <Route
                path="shipping-policies"
                element={<ShippingPolicySettings />}
              />
              <Route path="automations">
                <Route index element={<StoreRulesOverview />} />
                <Route path="templates" element={<StoreRuleTemplates />} />
                <Route
                  // => GET list - supports filtering by eventType using URL parameter
                  path="list"
                  element={<StoreRulesList />}
                />
                <Route
                  // => POST create - can have partial store rule when loaded; i.e. from template
                  path="create"
                  element={<StoreRuleForm />}
                />
                <Route
                  // => POST edit; GET one available through item details in list
                  path="edit/:storeRuleId"
                  element={<StoreRuleForm />}
                />
              </Route>
              <Route path="users" element={<UserSettings />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
    <Route element={<AuxiliaryLayout />}>
      <Route
        path="welcome"
        element={<UserWelcome />}
        loader={UserWelcomeLoader}
      />
      <Route path="sign-up" element={<SignUp />} loader={SignUpLoader} />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Route>,
);

export default function Router() {
  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}
