// TODO: This file is a work in progress probably be renamed and serve as the main file for all the filter types and definitions
import {
  ClaimReasonEnum,
  ClaimResolutionMethodEnum,
  CrewClaimResolutionMethodEnum,
  crewClaimResolutionMethodEnumName,
  CrewClaimRollupStatusCode,
  crewClaimRollupStatusCodeName,
  CrewMerchantApi,
  shippingClaimReasonName,
  TrackingStatusCode,
  trackingStatusCodeName,
} from 'corso-types';
import { Duration, endOfDay, startOfDay, sub } from 'date-fns';
import { useMemo } from 'react';
import { z } from 'zod';
import { useClaimTagOptions } from '~/hooks/useClaimTags';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import { shippingClaimResolutionMethodName } from '~/utils/enumNameMaps';
import {
  booleanFilterSchema,
  dateFilterSchema,
  DatePreset,
  Filter,
  FilterControlType,
  multiSelectFilterSchema,
} from './Filter';

type Search = CrewMerchantApi.RequestBody<'/:storeId/search', 'post'>;

type FilterId<Entity extends Search['kind']> = Exclude<
  keyof Extract<Search, { kind: Entity }>,
  | 'kind'
  | 'cursor'
  | 'take'
  | 'orderBy'
  | 'endDate'
  | 'startDate'
  | 'searchTerm'
>;

type FilterDef<Entity extends Search['kind']> = Filter & {
  id: FilterId<Entity>;
};

const presetDurations = {
  [DatePreset.today]: {},
  [DatePreset.last7Days]: { days: 7 },
  [DatePreset.last30Days]: { days: 30 },
  [DatePreset.last90Days]: { days: 90 },
  [DatePreset.last12Months]: { months: 12 },
} satisfies Record<Exclude<DatePreset, DatePreset.custom>, Duration>;

export const returnFiltersSchema = z.object({
  createdOn: dateFilterSchema.merge(z.object({ id: z.literal('createdOn') })),
  source: multiSelectFilterSchema.merge(z.object({ id: z.literal('source') })),
  statusCodes: multiSelectFilterSchema.merge(
    z.object({ id: z.literal('statusCodes') }),
  ),
  shipmentStatus: multiSelectFilterSchema.merge(
    z.object({ id: z.literal('shipmentStatus') }),
  ),
  hasError: booleanFilterSchema.merge(z.object({ id: z.literal('hasError') })),
  requestedResolutionMethods: multiSelectFilterSchema.merge(
    z.object({ id: z.literal('requestedResolutionMethods') }),
  ),
  claimTaggedWith: multiSelectFilterSchema.merge(
    z.object({ id: z.literal('claimTaggedWith') }),
  ),
  hasInstantExchange: booleanFilterSchema.merge(
    z.object({ id: z.literal('hasInstantExchange') }),
  ),
}) satisfies z.ZodSchema<Record<FilterId<'Return'>, FilterDef<'Return'>>>;

export const warrantyFiltersSchema = z.object({
  createdOn: dateFilterSchema.merge(z.object({ id: z.literal('createdOn') })),
  source: multiSelectFilterSchema.merge(z.object({ id: z.literal('source') })),
  statusCodes: multiSelectFilterSchema.merge(
    z.object({ id: z.literal('statusCodes') }),
  ),
  shipmentStatus: multiSelectFilterSchema.merge(
    z.object({ id: z.literal('shipmentStatus') }),
  ),
  hasError: booleanFilterSchema.merge(z.object({ id: z.literal('hasError') })),
  requestedResolutionMethods: multiSelectFilterSchema.merge(
    z.object({ id: z.literal('requestedResolutionMethods') }),
  ),
  claimTaggedWith: multiSelectFilterSchema.merge(
    z.object({ id: z.literal('claimTaggedWith') }),
  ),
}) satisfies z.ZodSchema<
  Record<
    Exclude<FilterId<'Warranty'>, 'hasInstantExchange'>,
    FilterDef<'Warranty'>
  >
>;

export const shippingFiltersSchema = z.object({
  createdOn: dateFilterSchema.merge(z.object({ id: z.literal('createdOn') })),
  claimReasons: multiSelectFilterSchema.merge(
    z.object({ id: z.literal('claimReasons') }),
  ),
  resolutionMethods: multiSelectFilterSchema.merge(
    z.object({ id: z.literal('resolutionMethods') }),
  ),
}) satisfies z.ZodSchema<Record<FilterId<'Shipping'>, FilterDef<'Shipping'>>>;

export const orderFilterSchema = z.object({
  createdOn: dateFilterSchema.merge(z.object({ id: z.literal('createdOn') })),
}) satisfies z.ZodSchema<Record<FilterId<'Order'>, FilterDef<'Order'>>>;

export const registrationFiltersSchema = z.object({
  createdOn: dateFilterSchema.merge(z.object({ id: z.literal('createdOn') })),
  estimatedPurchaseDate: dateFilterSchema.merge(
    z.object({ id: z.literal('estimatedPurchaseDate') }),
  ),
  salesChannels: multiSelectFilterSchema.merge(
    z.object({ id: z.literal('salesChannels') }),
  ),
}) satisfies z.ZodSchema<
  Record<
    Exclude<
      FilterId<'Registration'>,
      'estimatedPurchaseDateStart' | 'estimatedPurchaseDateEnd'
    >,
    FilterDef<'Registration'>
  >
>;

const filterDefinition = z.union([
  returnFiltersSchema,
  warrantyFiltersSchema,
  shippingFiltersSchema,
  registrationFiltersSchema,
]);
export type FilterDefinition = z.infer<typeof filterDefinition>;

const computeDateFilterValue = (
  value: NonNullable<
    Extract<Filter, { type: FilterControlType.date }>['value']
  >,
) => {
  switch (value.preset) {
    case DatePreset.today:
    case DatePreset.last7Days:
    case DatePreset.last30Days:
    case DatePreset.last90Days:
    case DatePreset.last12Months:
      return {
        start: startOfDay(
          sub(new Date(), presetDurations[value.preset]),
        ).toISOString(),
        end: null,
      };

    case DatePreset.custom: {
      const start =
        value.range?.starting ?
          startOfDay(value.range.starting).toISOString()
        : null;
      const end =
        value.range?.ending ? endOfDay(value.range.ending).toISOString() : null;
      return { start, end };
    }
    default:
      return { start: null, end: null };
  }
};

// TODO automation-like type safety for filters
// ! this allows us to lie in the types, because there's no type-enforced relationship between the filter and it's resulting type, anything additional properties can really be almost anything; i.e. any arbitrary key can be added with any value, and duck typing says it's fine
export const convertFiltersToSearch = (filters: Partial<FilterDefinition>) =>
  Object.values<Filter>(filters).reduce<
    Omit<Search, 'cursor' | 'take' | 'orderBy' | 'searchTerm' | 'kind'>
  >((values, filter) => {
    // check for null or undefined values since boolean filters can have false as a value
    if (filter.value === null || filter.value === undefined) return values;

    if (filter.type === FilterControlType.date) {
      return {
        ...values,
        [filter.id]: computeDateFilterValue(filter?.value),
      };
    }

    return { ...values, [filter.id]: filter.value };
  }, {});

const createdOn = {
  id: 'createdOn',
  label: 'Date',
  type: FilterControlType.date,
  options: [
    { value: DatePreset.today, label: 'Today' },
    { value: DatePreset.last7Days, label: 'Last 7 Days' },
    { value: DatePreset.last30Days, label: 'Last 30 Days' },
    { value: DatePreset.last90Days, label: 'Last 90 Days' },
    { value: DatePreset.last12Months, label: 'Last 12 Months' },
    { value: DatePreset.custom, label: 'Custom' },
  ],
} satisfies FilterDef<Search['kind']>;

const source = {
  id: 'source',
  label: 'Source',
  type: FilterControlType.multiselect,
  options: [
    { value: 'Merchant_App', label: 'Merchant App' },
    { value: 'Customer_App', label: 'Customer App' },
  ],
} satisfies FilterDef<'Return' | 'Warranty'>;

const shipmentStatus = {
  id: 'shipmentStatus',
  label: 'Shipment Status',
  type: FilterControlType.multiselect,
  options: Object.values(TrackingStatusCode).map((status) => ({
    value: status,
    label: trackingStatusCodeName[status],
  })),
} satisfies FilterDef<'Return' | 'Warranty'>;

const hasError = {
  id: 'hasError',
  label: 'Error',
  type: FilterControlType.boolean,
  options: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
} satisfies FilterDef<'Return' | 'Warranty'>;

const statusCodes = {
  id: 'statusCodes',
  label: 'Status',
  type: FilterControlType.multiselect,
  options: Object.values(CrewClaimRollupStatusCode).map((code) => ({
    value: code,
    label: crewClaimRollupStatusCodeName[code],
  })),
} satisfies FilterDef<'Return' | 'Warranty'>;

const defineReturnFilters = (tags: string[]) =>
  ({
    createdOn,
    source,
    statusCodes,
    shipmentStatus,
    hasError,
    requestedResolutionMethods: {
      id: 'requestedResolutionMethods',
      label: 'Resolution Method',
      type: FilterControlType.multiselect,
      options: Object.values(CrewClaimResolutionMethodEnum)
        .filter(
          (method) =>
            method !== CrewClaimResolutionMethodEnum.repair &&
            method !== CrewClaimResolutionMethodEnum.warrantyReview,
        )
        .map((method) => ({
          value: method,
          label: crewClaimResolutionMethodEnumName[method],
        })),
    },
    claimTaggedWith: {
      id: 'claimTaggedWith',
      label: 'Tagged With',
      type: FilterControlType.multiselect,
      options: tags.map((tag) => ({ value: tag, label: tag })),
    },
    hasInstantExchange: {
      id: 'hasInstantExchange',
      label: 'Instant Exchange',
      type: FilterControlType.boolean,
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
  }) satisfies z.infer<typeof returnFiltersSchema>;

const defineWarrantyFilters = (tags: string[]) =>
  ({
    createdOn,
    source,
    statusCodes,
    shipmentStatus,
    hasError,
    requestedResolutionMethods: {
      id: 'requestedResolutionMethods',
      label: 'Resolution Method',
      type: FilterControlType.multiselect,
      options: Object.values(CrewClaimResolutionMethodEnum)
        .filter(
          (method) =>
            method !== CrewClaimResolutionMethodEnum.repair &&
            method !== CrewClaimResolutionMethodEnum.variantExchange,
        )
        .map((method) => ({
          value: method,
          label: crewClaimResolutionMethodEnumName[method],
        })),
    },
    claimTaggedWith: {
      id: 'claimTaggedWith',
      label: 'Tagged With',
      type: FilterControlType.multiselect,
      options: tags.map((tag) => ({ value: tag, label: tag })),
    },
  }) satisfies z.infer<typeof warrantyFiltersSchema>;

const defineShippingFilters = (offeredClaimReasons: `${ClaimReasonEnum}`[]) =>
  ({
    createdOn,

    claimReasons: {
      id: 'claimReasons',
      label: 'Reason',
      type: FilterControlType.multiselect,
      options: offeredClaimReasons.map((offeredReason) => ({
        value: offeredReason,
        label: shippingClaimReasonName[offeredReason],
      })),
    },
    resolutionMethods: {
      id: 'resolutionMethods',
      label: 'Resolution',
      type: FilterControlType.multiselect,
      options: Object.values(ClaimResolutionMethodEnum).map((value) => ({
        value,
        label: shippingClaimResolutionMethodName[value],
      })),
    },
  }) satisfies z.infer<typeof shippingFiltersSchema>;

const defineRegistrationFilters = (salesChannels: string[]) =>
  ({
    createdOn,

    estimatedPurchaseDate: {
      id: 'estimatedPurchaseDate',
      label: 'Purchase Date',
      type: FilterControlType.date,
      options: [
        { value: DatePreset.today, label: 'Today' },
        { value: DatePreset.last7Days, label: 'Last 7 Days' },
        { value: DatePreset.last30Days, label: 'Last 30 Days' },
        { value: DatePreset.last90Days, label: 'Last 90 Days' },
        { value: DatePreset.last12Months, label: 'Last 12 Months' },
        { value: DatePreset.custom, label: 'Custom' },
      ],
    },
    salesChannels: {
      id: 'salesChannels',
      label: 'Channel',
      type: FilterControlType.multiselect,
      options: salesChannels.map((channel) => ({
        value: channel,
        label: channel,
      })),
    },
  }) satisfies z.infer<typeof registrationFiltersSchema>;

export const useFilterDefinition = <Entity extends Search['kind']>(
  entity: Entity,
) => {
  const tags = useClaimTagOptions();
  const { data: registrationSettings } = useConfigSettings(
    ({ registrations }) => registrations,
  );
  const { data: offeredClaimReasons } = useConfigSettings(
    ({ shippingProtection }) =>
      shippingProtection.isConfigured ?
        shippingProtection.offeredClaimReasons
      : [],
  );

  return useMemo(() => {
    switch (entity) {
      case 'Return':
        return defineReturnFilters(tags.data ?? []);
      case 'Warranty':
        return defineWarrantyFilters(tags.data ?? []);
      case 'Shipping':
        return defineShippingFilters(offeredClaimReasons ?? []);
      case 'Registration':
        return defineRegistrationFilters(
          registrationSettings?.salesChannels ?? [],
        );
      default:
        throw new Error(`No filters defined for ${entity}`);
    }
  }, [tags.data, entity, offeredClaimReasons, registrationSettings]);
};
