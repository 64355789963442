import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import {
  CrewClaimResolutionMethodEnum,
  crewClaimResolutionMethodEnumName,
  crewClaimRollupStatusCodeName,
  CrewMerchantApi,
  shippingClaimReasonName,
} from 'corso-types';
import { ComponentProps } from 'react';
import Card from '~/components/Card';
import Icon from '~/components/Icon';
import RelativeDateTime from '~/components/RelativeDateTime';
import { Badge } from '~/components/ui/primitives/Badge';
import useIsDesktop from '~/hooks/useIsDesktop';
import { shippingClaimStatusName } from '~/utils/enumNameMaps';

type SearchRes = CrewMerchantApi.ResponseBody<'/:storeId/search', 'post'>;

type Data<Entity extends SearchRes['kind']> = Extract<
  SearchRes,
  { kind: Entity }
>['data'][number];

const shippingClaimResolutionMethodToBadgeVariant: Record<
  'Reorder' | 'Refund',
  ComponentProps<typeof Badge>['variant']
> = {
  Refund: 'danger',
  Reorder: 'success',
};

const resolutionMethodToBadgeVariant: Record<
  CrewClaimResolutionMethodEnum,
  ComponentProps<typeof Badge>['variant']
> = {
  [CrewClaimResolutionMethodEnum.giftCard]: 'info',
  [CrewClaimResolutionMethodEnum.variantExchange]: 'success',
  [CrewClaimResolutionMethodEnum.refund]: 'danger',
  [CrewClaimResolutionMethodEnum.replacementOrder]: 'success',
  [CrewClaimResolutionMethodEnum.warrantyReview]: 'info',
  // for completeness, the rest are default
  [CrewClaimResolutionMethodEnum.repair]: 'default',
};

function TagBadges({ tags }: { tags: string[] }) {
  const renderBadges = (badgeTags: string[]) =>
    badgeTags.map((tag) => <Badge key={tag}>{tag}</Badge>);

  return (
    <div className="flex flex-wrap gap-1">
      {tags.length > 2 ?
        <>
          <Badge>{tags[0]}</Badge>
          <Badge>{tags[1]}</Badge>
          <Popover>
            <PopoverTrigger onClick={(e) => e.stopPropagation()}>
              <Badge hoverable>+{tags.length - 2}</Badge>
            </PopoverTrigger>
            <PopoverContent align="end">
              <Card>{renderBadges(tags.slice(2))}</Card>
            </PopoverContent>
          </Popover>
        </>
      : renderBadges(tags)}
    </div>
  );
}

const defineClaimColumns = (isDesktop: boolean) => {
  const columnHelper = createColumnHelper<Data<'Return' | 'Warranty'>>();

  const columnDef = [
    columnHelper.accessor('externalId', {
      header: 'Claim',
      cell: ({ getValue, row }) => {
        const { lineItemHasError } = row.original;

        return (
          <div className="flex items-center justify-between font-semibold">
            #{getValue()}
            {lineItemHasError && (
              <Icon
                icon={faTriangleExclamation}
                className="inline size-4 text-orange-600"
              />
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('createdOn', {
      header: 'Date',
      cell: ({ getValue }) => (
        <RelativeDateTime data-testid="claimDate" dateTime={getValue()} />
      ),
    }),
    columnHelper.accessor('customerName', { header: 'Customer' }),
    columnHelper.accessor('claimRollupStatusCode', {
      header: 'Status',
      cell: ({ getValue }) => crewClaimRollupStatusCodeName[getValue()],
    }),
    columnHelper.accessor('resolutionMethods', {
      header: 'Resolution Methods',
      cell: ({ getValue }) => {
        const resolutionMethods = getValue();

        return (
          <div className="flex flex-wrap gap-1">
            {resolutionMethods.map((method) => (
              <Badge
                key={method}
                variant={resolutionMethodToBadgeVariant[method]}
              >
                {crewClaimResolutionMethodEnumName[method]}
              </Badge>
            ))}
          </div>
        );
      },
    }),

    columnHelper.accessor('tags', {
      header: 'Tags',
      cell: ({ getValue }) => <TagBadges tags={getValue()} />,
    }),
  ];

  const mobileColumDef = columnDef.filter(
    (column) =>
      column.header !== 'Resolution Methods' &&
      column.header !== 'Status' &&
      column.header !== 'Tags',
  );

  return isDesktop ? columnDef : mobileColumDef;
};

const defineShippingColumns = (isDesktop: boolean) => {
  const columnHelper = createColumnHelper<Data<'Shipping'>>();

  const columnDef = [
    columnHelper.accessor('id', {
      header: 'Claim',
      cell: (info) => (
        <div className="flex items-center justify-between font-semibold">
          #{info.getValue()}
        </div>
      ),
    }),
    columnHelper.accessor('createdOn', {
      header: 'Date',
      cell: ({ getValue }) => (
        <RelativeDateTime data-testid="shipmentDate" dateTime={getValue()} />
      ),
    }),
    columnHelper.accessor('customerName', { header: 'Customer' }),

    columnHelper.accessor('customerEmail', { header: 'Email' }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: ({ getValue }) => shippingClaimStatusName[getValue()],
    }),
    columnHelper.accessor('resolutionMethod', {
      header: 'Resolution Method',
      cell: ({ getValue }) => {
        const resolutionMethod = getValue();

        return (
          <div className="flex flex-wrap gap-1">
            <Badge
              variant={
                shippingClaimResolutionMethodToBadgeVariant[resolutionMethod]
              }
            >
              {resolutionMethod}
            </Badge>
          </div>
        );
      },
    }),
    columnHelper.accessor('reason', {
      header: 'Reason',
      cell: ({ getValue }) => shippingClaimReasonName[getValue()],
    }),
  ];

  const mobileColumDef = columnDef.filter(
    (column) =>
      column.header !== 'Status' &&
      column.header !== 'Reason' &&
      column.header !== 'Resolution Method' &&
      column.header !== 'Email',
  );

  return isDesktop ? columnDef : mobileColumDef;
};

const defineRegistrationColumns = (isDesktop: boolean) => {
  const columnHelper = createColumnHelper<Data<'Registration'>>();

  const columnDef = [
    columnHelper.accessor('externalId', {
      header: 'Registration',
      cell: ({ getValue }) => (
        <div className="flex items-center justify-between font-semibold">
          #{getValue()}
        </div>
      ),
    }),

    columnHelper.accessor('createdOn', {
      header: 'Date',
      cell: ({ getValue }) => (
        <RelativeDateTime
          data-testid="registrationDate"
          dateTime={getValue()}
        />
      ),
    }),

    columnHelper.accessor('customerName', { header: 'Customer' }),

    columnHelper.accessor('customerEmail', { header: 'Email' }),

    columnHelper.accessor('registrationChannelName', {
      header: 'Channel',
      cell: ({ getValue }) => {
        getValue();

        return (
          <div className="flex flex-wrap gap-1">
            <Badge variant="default">{getValue()}</Badge>
          </div>
        );
      },
    }),
  ];

  const mobileColumDef = columnDef.filter(
    (column) => column.header !== 'Email' && column.header !== 'Channel',
  );

  return isDesktop ? columnDef : mobileColumDef;
};

const defineOrderColumns = () => {
  const columnHelper = createColumnHelper<Data<'Order'>>();

  const columnDef = [
    columnHelper.accessor('orderNo', {
      header: 'Order Number',
      cell: ({ getValue }) => (
        <div className="flex items-center justify-between font-semibold">
          #{getValue()}
        </div>
      ),
    }),

    columnHelper.accessor('createdOn', {
      header: 'Date',
      cell: ({ getValue }) => (
        <RelativeDateTime data-testid="orderDate" dateTime={getValue()} />
      ),
    }),

    columnHelper.accessor('customerName', { header: 'Customer' }),

    columnHelper.accessor('customerEmail', { header: 'Email' }),
  ];

  return columnDef;
};

type ColumnDefinitions = {
  [K in SearchRes['kind']]: (isDesktop: boolean) => {
    [P in keyof Data<K>]: ColumnDef<Data<K>, Data<K>[P]>;
  }[keyof Data<K>][];
};

const columnDefinitions: ColumnDefinitions = {
  Return: defineClaimColumns,
  Warranty: defineClaimColumns,
  Shipping: defineShippingColumns,
  Registration: defineRegistrationColumns,
  Order: defineOrderColumns,
};

export const useColumnDefinition = <Entity extends SearchRes['kind']>(
  entity: Entity,
) => {
  const isDesktop = useIsDesktop();

  return columnDefinitions[entity](isDesktop);
};
