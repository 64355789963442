import { Link } from 'react-router-dom';
import CorsoLogo from './CorsoLogo';

export default function NoSubscription() {
  return (
    <main
      className="grid min-h-full grid-rows-[auto,_1fr] place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 dark:bg-corso-gray-800"
      data-testid="subscription-expired"
    >
      <div className="text-corso-blue-600 dark:text-white">
        <CorsoLogo size="lg" />
      </div>
      <div className="max-w-prose">
        <h1 className="mt-4 text-3xl font-medium tracking-tight text-corso-gray-800 sm:text-5xl dark:text-corso-gray-100">
          Subscription Expired
        </h1>
        <p className="mt-6 text-base leading-7 text-corso-gray-600 dark:text-corso-gray-500">
          You have reached the end of your subscription. Reach out to continue
          using the Corso app, free forever.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="https://corso.com/book-a-time"
            className="rounded-md bg-corso-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-corso-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            rel="noreferrer"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </main>
  );
}
