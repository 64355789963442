/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ArrowTopRightOnSquareIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { CrewMerchantUi, ShopifyAppSubscriptionStatusEnum } from 'corso-types';
import {
  ChangeEventHandler,
  FormEventHandler,
  PropsWithChildren,
  ReactNode,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import Button, { LinkButton } from '~/components/Button';
import Card from '~/components/Card';
import ClipboardButton from '~/components/ClipboardButton';
import {
  PasswordInput,
  SupportingText,
  SwitchInput,
  TextInput,
  UrlInput,
} from '~/components/field';
import IconAction from '~/components/IconAction';
import InfoPopover from '~/components/InfoPopover';
import Modal from '~/components/Modal';
import Page from '~/components/Page';
import Separator from '~/components/Separator';
import SpinnerIcon from '~/components/SpinnerIcon';
import { MultiSelect } from '~/components/ui/MultiSelect';
import { Badge } from '~/components/ui/primitives/Badge';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import {
  useIntegrationSettingsData,
  useIntegrationSettingsUpdate,
  useKlaviyoDisconnect,
  useStripeDisconnect,
} from '~/hooks/useIntegrationSettings';
import { IntegrationSettings } from '~/types';

const returnWarrantyProtect =
  'Built for Returns, Warranties & Shipping Protection';

const returnWarranty = 'Built for Returns & Warranties';

const shippingProtection = 'Built for Shipping Protection';

function IntegrationSection({
  children,
  title,
}: PropsWithChildren<{ title: string }>) {
  return (
    <Card>
      <Card.Title as="h3">{title}</Card.Title>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2">{children}</div>
    </Card>
  );
}

function IntegrationCard({
  imgUrl,
  title,
  description,
  action,
  worksWith,
}: {
  imgUrl: string;
  title: string;
  description?: ReactNode;
  action?: ReactNode;
  worksWith?: string;
}) {
  return (
    <Card>
      <div className="sr-only">
        <Card.Title as="h4">{title}</Card.Title>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-0.5">
          <img src={imgUrl} alt={title} className="h-10 w-auto" aria-hidden />
          {worksWith && (
            <InfoPopover title="Works With">
              <InfoPopover.Prose>{worksWith}</InfoPopover.Prose>
            </InfoPopover>
          )}
        </div>
        {action}
      </div>
      {description && (
        <>
          <Separator />
          <SupportingText>{description}</SupportingText>
        </>
      )}
    </Card>
  );
}

function AppSubscription() {
  const { data } = useIntegrationSettingsData();

  const {
    appSubscriptionStatus = ShopifyAppSubscriptionStatusEnum.pending,
    appSubscriptionConfirmationUrl,
  } = data?.appSubscription ?? {};
  const content = data?.appSubscriptionStatusContent;

  return (
    <div className="col-span-full">
      <IntegrationCard
        imgUrl="https://cdn.corso.com/img/shopify-1.png"
        title="Shopify Status"
        description={content?.supportingText}
        action={
          content?.needsAction && appSubscriptionConfirmationUrl ?
            <LinkButton
              className="w-28 sm:w-40"
              variant="primary"
              to={appSubscriptionConfirmationUrl}
            >
              Approve
            </LinkButton>
          : <Badge
              variant={content?.badgeVariant}
              className={twMerge(
                'w-28 justify-center px-3 py-2 capitalize sm:w-40',
                content?.supportingText && 'row-span-2 place-self-center',
              )}
            >
              {appSubscriptionStatus}
            </Badge>
        }
      />
    </div>
  );
}

function ShipHeroForm({
  showForm,
  closeForm,
  values,
}: {
  showForm: boolean;
  closeForm: () => void;
  values: IntegrationSettings['shipHeroConfig'] | undefined;
}) {
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,
    control,
  } = useForm<CrewMerchantUi.ShipHeroUpdate>({
    resolver: zodResolver(CrewMerchantUi.shipHeroUpdateSchema),
    values: {
      kind: 'shipHeroConfig',
      shipHeroConfig: values ?? {
        refreshToken: '',
        shouldCreateRma: false,
      },
    },
  });

  const closeHandler = () => {
    closeForm();
    reset();
  };

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit(
      (updates) => saveChanges(updates).then(closeHandler).catch(console.error),
      console.error,
    )(event).catch(console.error);
  };

  return (
    <Modal
      show={showForm}
      onClose={closeHandler}
      title="ShipHero"
      description="Configure your ShipHero account."
    >
      <form className="flex flex-col gap-6" onSubmit={submitHandler}>
        <Controller
          control={control}
          name="shipHeroConfig.shouldCreateRma"
          render={({ field: { value, onChange } }) => (
            <SwitchInput
              id="shiphero-create-rma"
              label="Create RMA"
              defaultChecked={value}
              onChange={onChange}
            />
          )}
        />

        <PasswordInput
          id="shiphero-refresh-token"
          label="Refresh Token"
          required
          {...register('shipHeroConfig.refreshToken')}
          error={
            'shipHeroConfig' in errors ?
              errors.shipHeroConfig?.refreshToken?.message
            : undefined
          }
        />

        <div className="flex flex-row justify-end gap-2">
          <Button className="w-20" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="w-20"
            variant="primary"
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            {!isSubmitting ? 'Save' : <SpinnerIcon />}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function ShipHero() {
  const { data } = useIntegrationSettingsData();
  const { mutate: saveChanges } = useIntegrationSettingsUpdate();
  const { shipHeroConfig } = data ?? {};
  const [showForm, setShowForm] = useState(false);

  const shipHeroEnabled = !!shipHeroConfig;

  const disconnectHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;

    if (!checked && shipHeroConfig) {
      saveChanges({
        kind: 'shipHeroConfig',
        shipHeroConfig: { ...shipHeroConfig, disconnect: true },
      });
    } else {
      setShowForm(true);
    }
  };

  return (
    <>
      <IntegrationCard
        imgUrl="https://cdn.corso.com/img/shiphero.png"
        title="ShipHero"
        worksWith={returnWarranty}
        description={
          shipHeroEnabled ?
            'ShipHero is connected.'
          : 'Connect a ShipHero account to create RMA requests.'
        }
        action={
          <div className="flex flex-row items-center gap-2">
            <SwitchInput
              id="shiphero-switch"
              label="Connect or disconnect ShipHero account"
              labelVisuallyHidden
              checked={shipHeroEnabled}
              onChange={disconnectHandler}
            />
            {shipHeroEnabled && (
              <IconAction.Button
                icon={Cog6ToothIcon}
                onClick={() => setShowForm(true)}
                title="Edit ShipHero Configuration"
              />
            )}
          </div>
        }
      />
      <ShipHeroForm
        showForm={showForm}
        values={shipHeroConfig}
        closeForm={() => setShowForm(false)}
      />
    </>
  );
}

function ShipBobForm({
  showForm,
  closeForm,
  values,
}: {
  showForm: boolean;
  closeForm: () => void;
  values: IntegrationSettings['shipBobConfig'] | undefined;
}) {
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,

    control,
  } = useForm<CrewMerchantUi.ShipBobUpdate>({
    resolver: zodResolver(CrewMerchantUi.shipBobUpdateSchema),
    values: {
      kind: 'shipBobConfig',
      shipBobConfig: values ?? {
        accessToken: '',
        shouldCreateRma: false,
      },
    },
  });

  const closeHandler = () => {
    closeForm();
    reset();
  };

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit(
      (updates) => saveChanges(updates).then(closeHandler).catch(console.error),
      console.error,
    )(event).catch(console.error);
  };

  return (
    <Modal
      show={showForm}
      onClose={closeHandler}
      title="ShipBob"
      description="Configure your ShipBob account."
    >
      <form className="flex flex-col gap-6" onSubmit={submitHandler}>
        <Controller
          control={control}
          name="shipBobConfig.shouldCreateRma"
          render={({ field: { value, onChange } }) => (
            <SwitchInput
              id="shipbob-create-rma-2"
              label="Create RMA"
              details="Create an RMA request at the original fulfillment center when a return is marked as In-Transit."
              defaultChecked={value}
              onChange={onChange}
            />
          )}
        />

        <PasswordInput
          id="shipbob-access-token"
          label="Access Token"
          required
          {...register('shipBobConfig.accessToken')}
          error={
            'shipBobConfig' in errors ?
              errors.shipBobConfig?.accessToken?.message
            : undefined
          }
        />

        <div className="flex flex-row justify-end gap-2">
          <Button className="w-20" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="w-20"
            variant="primary"
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            {!isSubmitting ? 'Save' : <SpinnerIcon />}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function ShipBob() {
  const { data } = useIntegrationSettingsData();
  const { mutate: saveChanges } = useIntegrationSettingsUpdate();
  const { shipBobConfig } = data ?? {};
  const [showForm, setShowForm] = useState(false);

  const shipBobEnabled = !!shipBobConfig;

  const disconnectHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;

    if (!checked && shipBobEnabled) {
      saveChanges({
        kind: 'shipBobConfig',
        shipBobConfig: { ...shipBobConfig, disconnect: true },
      });
    } else {
      setShowForm(true);
    }
  };

  return (
    <>
      <IntegrationCard
        imgUrl="https://cdn.corso.com/img/shipbob-3.png"
        title="ShipBob"
        worksWith={returnWarranty}
        description={
          shipBobConfig ?
            'ShipBob is connected.'
          : 'Connect a ShipBob account to create RMA requests.'
        }
        action={
          <div className="flex flex-row items-center gap-2">
            <SwitchInput
              id="shipBob-switch"
              label="Connect or disconnect ShipBob account"
              labelVisuallyHidden
              checked={shipBobEnabled}
              onChange={disconnectHandler}
            />
            {shipBobEnabled && (
              <IconAction.Button
                icon={Cog6ToothIcon}
                onClick={() => setShowForm(true)}
                title="Edit ShipBob Configuration"
              />
            )}
          </div>
        }
      />
      <ShipBobForm
        showForm={showForm}
        values={shipBobConfig}
        closeForm={() => setShowForm(false)}
      />
    </>
  );
}

function BlueBoxForm({
  showForm,
  closeForm,
  values,
}: {
  showForm: boolean;
  closeForm: () => void;
  values: IntegrationSettings['blueBoxConfig'] | undefined;
}) {
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,

    control,
  } = useForm<CrewMerchantUi.BlueBoxUpdate>({
    resolver: zodResolver(CrewMerchantUi.blueBoxUpdateSchema),
    values: {
      kind: 'blueBoxConfig',
      blueBoxConfig: values ?? {
        password: '',
        username: '',
        shouldCreateRma: false,
      },
    },
  });

  const closeHandler = () => {
    closeForm();
    reset();
  };

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit(
      (updates) => saveChanges(updates).then(closeHandler).catch(console.error),
      console.error,
    )(event).catch(console.error);
  };

  return (
    <Modal
      show={showForm}
      onClose={closeHandler}
      title="BlueBox"
      description="Configure your BlueBox account."
    >
      <form className="flex flex-col gap-6" onSubmit={submitHandler}>
        <Controller
          control={control}
          name="blueBoxConfig.shouldCreateRma"
          render={({ field: { value, onChange } }) => (
            <SwitchInput
              id="blue-box-create-rma"
              label="Create RMA"
              details="Create an RMA request when a return is marked as In-Transit."
              defaultChecked={value}
              onChange={onChange}
            />
          )}
        />

        <TextInput
          id="blue-box-username"
          label="Username"
          required
          {...register('blueBoxConfig.username')}
          error={
            'blueBoxConfig' in errors ?
              errors.blueBoxConfig?.username?.message
            : undefined
          }
        />

        <PasswordInput
          id="blue-box-password"
          label="Password"
          required
          {...register('blueBoxConfig.password')}
          error={
            'blueBoxConfig' in errors ?
              errors.blueBoxConfig?.password?.message
            : undefined
          }
        />

        <div className="flex flex-row justify-end gap-2">
          <Button className="w-20" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="w-20"
            variant="primary"
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            {!isSubmitting ? 'Save' : <SpinnerIcon />}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function BlueBox() {
  const { data } = useIntegrationSettingsData();
  const { mutate: saveChanges } = useIntegrationSettingsUpdate();
  const { blueBoxConfig } = data ?? {};
  const [showForm, setShowForm] = useState(false);

  const blueBoxEnabled = !!blueBoxConfig;

  const disconnectHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;

    if (!checked && blueBoxEnabled) {
      saveChanges({
        kind: 'blueBoxConfig',
        blueBoxConfig: { ...blueBoxConfig, disconnect: true },
      });
    } else {
      setShowForm(true);
    }
  };

  return (
    <>
      <IntegrationCard
        imgUrl="https://cdn.corso.com/img/idf.png"
        title="BlueBox"
        worksWith={returnWarranty}
        description={
          blueBoxConfig ?
            'BlueBox is connected.'
          : 'Connect a BlueBox account to create RMA requests.'
        }
        action={
          <div className="flex flex-row items-center gap-2">
            <SwitchInput
              id="blueBox-switch"
              label="Connect or disconnect BlueBox account"
              labelVisuallyHidden
              checked={blueBoxEnabled}
              onChange={disconnectHandler}
            />
            {blueBoxEnabled && (
              <IconAction.Button
                icon={Cog6ToothIcon}
                onClick={() => setShowForm(true)}
                title="Edit BlueBox Configuration"
              />
            )}
          </div>
        }
      />
      <BlueBoxForm
        showForm={showForm}
        values={blueBoxConfig}
        closeForm={() => setShowForm(false)}
      />
    </>
  );
}

function SendlaneForm({
  showForm,
  closeForm,
  values,
}: {
  showForm: boolean;
  closeForm: () => void;
  values: IntegrationSettings['sendLaneConfig'] | undefined;
}) {
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,

    control,
  } = useForm<CrewMerchantUi.SendlaneUpdate>({
    resolver: zodResolver(CrewMerchantUi.sendlaneUpdateSchema),
    values: {
      kind: 'sendLaneConfig',
      sendLaneConfig: values ?? {
        accessToken: '',
      },
    },
  });

  const closeHandler = () => {
    closeForm();
    reset();
  };

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit(
      (updates) => saveChanges(updates).then(closeHandler).catch(console.error),
      console.error,
    )(event).catch(console.error);
  };

  return (
    <Modal
      show={showForm}
      onClose={closeHandler}
      title="Sendlane"
      description="Configure your Sendlane account."
    >
      <form className="flex flex-col gap-6" onSubmit={submitHandler}>
        <PasswordInput
          id="sendlane-access-token"
          label="Access Token"
          required
          {...register('sendLaneConfig.accessToken')}
          error={
            'sendLaneConfig' in errors ?
              errors.sendLaneConfig?.accessToken?.message
            : undefined
          }
        />

        <div className="flex flex-row justify-end gap-2">
          <Button className="w-20" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="w-20"
            variant="primary"
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            {!isSubmitting ? 'Save' : <SpinnerIcon />}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function SendLaneKnowledgeBase() {
  return (
    <a
      className="text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
      href="https://help.corso.com/sendlane-integration"
      target="_blank"
      rel="noreferrer"
    >
      Learn More
    </a>
  );
}

function SendlaneConfig() {
  const { data } = useIntegrationSettingsData();
  const { mutate: saveChanges } = useIntegrationSettingsUpdate();
  const { sendLaneConfig } = data ?? {};
  const [showForm, setShowForm] = useState(false);

  const isSendlaneEnabled = !!sendLaneConfig;

  const disconnectHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;

    if (!checked && isSendlaneEnabled) {
      saveChanges({
        kind: 'sendLaneConfig',
        sendLaneConfig: { ...sendLaneConfig, disconnect: true },
      });
    } else {
      setShowForm(true);
    }
  };

  const connectedText =
    isSendlaneEnabled ?
      'Sendlane is connected.'
    : 'Connect a Sendlane account.';

  return (
    <>
      <IntegrationCard
        imgUrl="https://cdn.corso.com/img/sendlane.png"
        title="BlueBox"
        worksWith={returnWarranty}
        description={
          <div>
            <p>
              {connectedText} <SendLaneKnowledgeBase />
            </p>
          </div>
        }
        action={
          <div className="flex flex-row items-center gap-2">
            <SwitchInput
              id="sendlane-switch"
              label="Connect or disconnect Sendlane account"
              labelVisuallyHidden
              checked={isSendlaneEnabled}
              onChange={disconnectHandler}
            />
            {isSendlaneEnabled && (
              <IconAction.Button
                icon={Cog6ToothIcon}
                onClick={() => setShowForm(true)}
                title="Edit Sendlane Configuration"
              />
            )}
          </div>
        }
      />
      <SendlaneForm
        showForm={showForm}
        values={sendLaneConfig}
        closeForm={() => setShowForm(false)}
      />
    </>
  );
}

function StripeConfig() {
  const { data: { stripeConfig } = {} } = useIntegrationSettingsData();
  const { mutate: disconnectStripe, isPending } = useStripeDisconnect();

  if (!stripeConfig) return null;

  const { connected } = stripeConfig;
  const changeHandler = () =>
    connected ?
      disconnectStripe(stripeConfig.disconnectOauthUrl)
    : window.open(stripeConfig.startOauthUrl, '_blank');

  return (
    <IntegrationCard
      imgUrl="https://cdn.corso.com/img/stripe.png"
      title="Stripe"
      worksWith={returnWarranty}
      description={
        connected ?
          'Stripe is connected.'
        : 'Connect a Stripe account to collect additional funds from customers when necessary.'
      }
      action={
        <SwitchInput
          id="stripe-switch"
          label="Connect or disconnect Stripe account"
          labelVisuallyHidden
          checked={connected}
          disabled={isPending}
          onChange={changeHandler}
        />
      }
    />
  );
}

function KlaviyoKnowledgeBase() {
  return (
    <a
      className="text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
      href="https://help.corso.com/klaviyo-integration"
      target="_blank"
      rel="noreferrer"
    >
      Learn More
    </a>
  );
}

function KlaviyoConfig() {
  const { data: { klaviyoConfig } = {} } = useIntegrationSettingsData();
  const { mutate: disconnectKlaviyo, isPending } = useKlaviyoDisconnect();

  if (!klaviyoConfig) return null;

  const { connected } = klaviyoConfig;
  const changeHandler = () =>
    connected ?
      disconnectKlaviyo(klaviyoConfig.disconnectOauthUrl)
    : window.open(klaviyoConfig.startOauthUrl, '_blank');

  const connectedText =
    connected ? 'Klaviyo is connected.' : 'Connect a Klaviyo account.';

  return (
    <IntegrationCard
      imgUrl="https://cdn.corso.com/img/klaviyo.png"
      title="Stripe"
      worksWith={returnWarranty}
      description={
        <div>
          <p>
            {connectedText} <KlaviyoKnowledgeBase />
          </p>
        </div>
      }
      action={
        <SwitchInput
          id="klaviyo-switch"
          label="Connect or disconnect Klaviyo"
          labelVisuallyHidden
          checked={connected}
          disabled={isPending}
          onChange={changeHandler}
        />
      }
    />
  );
}

function AttentiveConfig() {
  return (
    <IntegrationCard
      imgUrl="https://cdn.corso.com/img/attentive-1.png"
      title="Attentive"
      worksWith={returnWarranty}
      description="Coming soon!"
      action={
        <SwitchInput
          id="attentive-switch"
          label="Connect or disconnect Attentive"
          labelVisuallyHidden
          checked={false}
          disabled
        />
      }
    />
  );
}

function XbFulfillmentForm({
  showForm,
  closeForm,
  values,
}: {
  showForm: boolean;
  closeForm: () => void;
  values: IntegrationSettings['xbFulfillmentConfig'] | undefined;
}) {
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,
    control,
  } = useForm<CrewMerchantUi.XBFulfillmentUpdate>({
    resolver: zodResolver(CrewMerchantUi.xBFulfillmentUpdateSchema),
    values: {
      kind: 'xbFulfillmentConfig',
      xbFulfillmentConfig: values ?? {
        password: '',
        username: '',
        shouldCreateRma: false,
        endpoint: '',
      },
    },
  });

  const closeHandler = () => {
    closeForm();
    reset();
  };

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit(
      (updates) => saveChanges(updates).then(closeHandler).catch(console.error),
      console.error,
    )(event).catch(console.error);
  };

  return (
    <Modal
      show={showForm}
      onClose={closeHandler}
      title="XB Fulfillment"
      description="Configure your XB Fulfillment account."
    >
      <form className="flex flex-col gap-6" onSubmit={submitHandler}>
        <TextInput
          id="xb-fulfillment-endpoint"
          label="Endpoint"
          required
          {...register('xbFulfillmentConfig.endpoint')}
          error={
            'xbFulfillmentConfig' in errors ?
              errors.xbFulfillmentConfig?.endpoint?.message
            : undefined
          }
        />

        <TextInput
          id="xb-fulfillment-username"
          label="Username"
          required
          {...register('xbFulfillmentConfig.username')}
          error={
            'xbFulfillmentConfig' in errors ?
              errors.xbFulfillmentConfig?.username?.message
            : undefined
          }
        />

        <PasswordInput
          id="xb-fulfillment-password"
          label="Password"
          required
          {...register('xbFulfillmentConfig.password')}
          error={
            'xbFulfillmentConfig' in errors ?
              errors.xbFulfillmentConfig?.password?.message
            : undefined
          }
        />

        <Controller
          control={control}
          name="xbFulfillmentConfig.shouldCreateRma"
          render={({ field: { value, onChange } }) => (
            <SwitchInput
              id="xb-fulfillment-create-rma"
              label="Create RMA"
              defaultChecked={value}
              onChange={onChange}
            />
          )}
        />

        <div className="flex flex-row justify-end gap-2">
          <Button className="w-20" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="w-20"
            variant="primary"
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            {!isSubmitting ? 'Save' : <SpinnerIcon />}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function XbFulfillment() {
  const { data } = useIntegrationSettingsData();
  const { mutate: saveChanges } = useIntegrationSettingsUpdate();

  const { xbFulfillmentConfig } = data ?? {};

  const [showForm, setShowForm] = useState(false);

  const xBFulfillmentEnabled = !!xbFulfillmentConfig;

  const disconnectHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;

    if (!checked && xbFulfillmentConfig) {
      saveChanges({
        kind: 'xbFulfillmentConfig',
        xbFulfillmentConfig: { ...xbFulfillmentConfig, disconnect: true },
      });
    } else {
      setShowForm(true);
    }
  };

  return (
    <>
      <IntegrationCard
        imgUrl="https://cdn.corso.com/img/xb-fulfillment.png"
        title="Xb-Fulfillment"
        worksWith={returnWarranty}
        description={
          xbFulfillmentConfig ?
            'XB Fulfillment is connected.'
          : 'Connect an XB Fulfillment account to create RMA requests.'
        }
        action={
          <div className="flex flex-row items-center gap-2">
            <SwitchInput
              id="xb-fulfillment-switch"
              label="Connect or disconnect XB Fulfillment account"
              labelVisuallyHidden
              checked={xBFulfillmentEnabled}
              onChange={disconnectHandler}
            />
            {xBFulfillmentEnabled && (
              <IconAction.Button
                icon={Cog6ToothIcon}
                onClick={() => setShowForm(true)}
                title="Edit XB Fulfillment Configuration"
              />
            )}
          </div>
        }
      />
      <XbFulfillmentForm
        showForm={showForm}
        values={xbFulfillmentConfig}
        closeForm={() => setShowForm(false)}
      />
    </>
  );
}

function PostscriptConfig() {
  return (
    <IntegrationCard
      imgUrl="https://cdn.corso.com/img/postscript.png"
      title="Postscript"
      worksWith={returnWarranty}
      description="Coming soon!"
      action={
        <SwitchInput
          id="postscript-switch"
          label="Connect or disconnect a Postscript account"
          labelVisuallyHidden
          checked={false}
          disabled
        />
      }
    />
  );
}

function GorgiasConfig() {
  const { data: { gorgiasConfig } = {} } = useIntegrationSettingsData();

  const gorgiasEnabled = !!gorgiasConfig;

  const description =
    gorgiasEnabled ?
      `Gorgias is connected.`
    : <div className="space-y-1">
        <p>
          {' '}
          Connect a Gorgias account.{' '}
          <a
            className="text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
            href="https://help.corso.com/gorgias-app-integration"
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
        </p>
      </div>;

  const changeHandler = () =>
    gorgiasEnabled ?
      window.open(gorgiasConfig.appUrl, '_blank')
    : window.open('https://www.gorgias.com/apps/corso-post-purchase', '_blank');

  return (
    <IntegrationCard
      worksWith={returnWarrantyProtect}
      imgUrl="https://cdn.corso.com/img/gorgias-2.png"
      title="Gorgias"
      description={description}
      action={
        <SwitchInput
          id="gorgias-switch"
          label="Connect or disconnect Gorgias account"
          labelVisuallyHidden
          defaultChecked={gorgiasEnabled}
          onChange={changeHandler}
        />
      }
    />
  );
}

function GladlyConfig() {
  const { data } = useIntegrationSettingsData();
  const { gladlyConfig } = data ?? {};
  return (
    <IntegrationCard
      worksWith={returnWarrantyProtect}
      imgUrl="https://cdn.corso.com/img/gladly-1.png"
      title="Gladly"
      description={
        gladlyConfig ?
          'Please contact us to manage your Gladly account.'
        : 'Please contact us to connect your Gladly account.'
      }
      action={
        <SwitchInput
          id="gladly-switch"
          label="Connect or disconnect a Gladly account"
          labelVisuallyHidden
          defaultChecked={!!gladlyConfig}
          disabled
        />
      }
    />
  );
}

function KustomerConfig() {
  return (
    <IntegrationCard
      imgUrl="https://cdn.corso.com/img/kustomer-1.png"
      title="Kustomer"
      worksWith={returnWarranty}
      description="Coming soon!"
      action={
        <SwitchInput
          id="kustomer-switch"
          label="Connect or disconnect Kustomer"
          labelVisuallyHidden
          checked={false}
          disabled
        />
      }
    />
  );
}

function ZowieConfig() {
  const { data } = useIntegrationSettingsData();
  const { zowieConfig } = data ?? {};
  return (
    <IntegrationCard
      worksWith={shippingProtection}
      imgUrl="https://cdn.corso.com/img/zowie.png"
      title="Zowie"
      description={
        zowieConfig ?
          'Please contact us to manage your Zowie account.'
        : 'Please contact us to connect your Zowie account.'
      }
      action={
        <SwitchInput
          id="gladly-switch"
          label="Connect or disconnect a Zowie account"
          labelVisuallyHidden
          defaultChecked={!!zowieConfig}
          disabled
        />
      }
    />
  );
}

function BrijConfigForm({
  showForm,
  closeForm,
  customerAppUrl,
}: {
  customerAppUrl: string;
  showForm: boolean;
  closeForm: () => void;
}) {
  const closeHandler = () => {
    closeForm();
  };

  return (
    <Modal show={showForm} onClose={closeHandler} title="Brij Configuration">
      <form className="flex flex-col gap-4">
        <UrlInput
          id="customer-portal"
          label="Start a Warranty Request"
          details="Provide this URL to your Brij representative to direct customers to start a warranty request."
          value={customerAppUrl}
          required
          readOnly
          disabled
          addon={{
            outsideEnd: (
              <div className="grid grid-cols-2 items-center gap-2 md:flex">
                <ClipboardButton onClick={() => customerAppUrl} />
                <IconAction.Link
                  to={customerAppUrl}
                  target="_blank"
                  title="Visit"
                  icon={ArrowTopRightOnSquareIcon}
                />
              </div>
            ),
          }}
        />
        <div className="flex flex-row justify-end gap-2">
          <Button className="w-20" onClick={closeHandler}>
            Cancel
          </Button>
          <Button className="w-20" variant="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function BrijConfig() {
  const [showForm, setShowForm] = useState(false);
  const { data: { brijConfig } = {} } = useIntegrationSettingsData();

  const brijEnabled = !!brijConfig;

  return (
    <>
      <IntegrationCard
        worksWith={returnWarranty}
        imgUrl="https://cdn.corso.com/img/brij.png"
        title="Brij"
        description={
          brijConfig ? 'Brij is connected.' : (
            'Please contact us to connect your Brij account.'
          )
        }
        action={
          <div className="flex flex-row items-center gap-2">
            <SwitchInput
              id="brij-switch"
              label="Connect or disconnect a Brij account"
              labelVisuallyHidden
              defaultChecked={brijEnabled}
            />

            {brijEnabled && (
              <IconAction.Button
                icon={Cog6ToothIcon}
                onClick={() => {
                  setShowForm(true);
                }}
                title="Edit Brij Configuration"
              />
            )}
          </div>
        }
      />
      {brijEnabled && (
        <BrijConfigForm
          customerAppUrl={brijConfig.startRegistrationUrl}
          showForm={showForm}
          closeForm={() => setShowForm(false)}
        />
      )}
    </>
  );
}

function VesylConfigForm({
  showForm,
  config,
  closeForm,
}: {
  showForm: boolean;
  config: IntegrationSettings['vesylConfig'] | undefined;
  closeForm: () => void;
}) {
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,
    control,
  } = useForm<CrewMerchantUi.VesylUpdate>({
    resolver: zodResolver(CrewMerchantUi.vesylUpdateSchema),
    values:
      config ?
        {
          kind: 'vesylConfig',
          ...config,
        }
      : {
          kind: 'vesylConfig',
          isEnabled: true,
          apiKey: '',
          provider: 'Vesyl',
          enabledCarriers: [],
        },
  });

  const closeHandler = () => {
    closeForm();
    reset();
  };

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit(
      (updates) =>
        saveChanges(updates)
          .then(() => closeHandler())
          .catch(console.error),
      console.error,
    )(event).catch(console.error);
  };

  return (
    <Modal
      show={showForm}
      onClose={closeHandler}
      title="Vesyl Configuration"
      description="Configure Vesyl to generate shipping labels."
    >
      <form className="flex flex-col gap-4" onSubmit={submitHandler}>
        <PasswordInput
          id="shipping-provider-api-key"
          label="API Key"
          required
          {...register('apiKey')}
          error={
            'shippingProvider' in errors ? errors.apiKey?.message : undefined
          }
        />

        {!!config?.carriers.length && (
          <Controller
            name="enabledCarriers"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MultiSelect
                label="Allowed Carriers"
                placeholder="Search for a carrier"
                options={config.carriers.map((carrier) => ({
                  label: carrier.name,
                  value: carrier.id.toString(),
                  searchValue: carrier.name,
                }))}
                value={value.map((carrier) => ({
                  label: carrier.name,
                  value: carrier.id.toString(),
                  searchValue: carrier.name,
                }))}
                onChange={(selected) => {
                  onChange(
                    selected.map((selectedCarrier) =>
                      config.carriers.find(
                        ({ id }) => id.toString() === selectedCarrier.value,
                      ),
                    ),
                  );
                }}
                details="Carriers to select from when generating shipping labels."
                error={errors.enabledCarriers?.message}
              />
            )}
          />
        )}

        <div className="flex flex-row justify-end gap-2">
          <Button className="w-20" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="w-20"
            variant="primary"
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            {!isSubmitting ? 'Save' : <SpinnerIcon />}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function VesylConfig() {
  const { data: { vesylConfig } = {} } = useIntegrationSettingsData();
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const [showForm, setShowForm] = useState(false);

  const description =
    vesylConfig ?
      `Vesyl is ${vesylConfig.isEnabled ? 'enabled.' : 'disabled.'}`
    : <div className="space-y-1">
        <p>Connect a Vesyl account to generate shipping labels.</p>
        <p>
          <a
            className="text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
            href="https://app.vesyl.com/register?ref=corso"
            target="_blank"
            rel="noreferrer"
          >
            Create a Vesyl account.
          </a>
        </p>
      </div>;

  const connectHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;
    if (!vesylConfig) {
      setShowForm(true);
    } else {
      saveChanges({
        kind: 'vesylConfig',
        ...vesylConfig,
        isEnabled: checked,
      }).catch(console.error);
    }
  };

  return (
    <>
      <IntegrationCard
        imgUrl="https://cdn.corso.com/img/vesyl.png"
        title="Vesyl"
        worksWith={returnWarranty}
        description={description}
        action={
          <div className="flex flex-row items-center gap-2">
            <SwitchInput
              id="connect-vesyl"
              label="Connect a Vesyl account"
              labelVisuallyHidden
              name="vesyl"
              checked={!!vesylConfig?.isEnabled}
              onChange={connectHandler}
            />

            {vesylConfig && (
              <IconAction.Button
                icon={Cog6ToothIcon}
                onClick={() => {
                  setShowForm(true);
                }}
                title="Edit Vesyl Configuration"
              />
            )}
          </div>
        }
      />

      <VesylConfigForm
        showForm={showForm}
        config={vesylConfig}
        closeForm={() => setShowForm(false)}
      />
    </>
  );
}

function EasyPostConfigForm({
  showForm,
  config,
  closeForm,
}: {
  showForm: boolean;
  config: IntegrationSettings['easyPostConfig'] | undefined;
  closeForm: () => void;
}) {
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,
    control,
  } = useForm<CrewMerchantUi.EasyPostUpdate>({
    resolver: zodResolver(CrewMerchantUi.easyPostUpdateSchema),
    values:
      config ?
        {
          kind: 'easyPostConfig',
          ...config,
        }
      : {
          kind: 'easyPostConfig',
          isEnabled: true,
          apiKey: '',
          provider: 'Easy_Post',
          carriers: [],
          enabledCarriers: [],
        },
  });

  const closeHandler = () => {
    closeForm();
    reset();
  };

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit(
      (updates) =>
        saveChanges(updates)
          .then(() => closeHandler())
          .catch(console.error),
      console.error,
    )(event).catch(console.error);
  };

  return (
    <Modal
      show={showForm}
      onClose={closeHandler}
      title="EasyPost Configuration"
      description="Configure EasyPost to generate shipping labels."
    >
      <form className="flex flex-col gap-4" onSubmit={submitHandler}>
        <PasswordInput
          id="shipping-provider-api-key"
          label="API Key"
          required
          {...register('apiKey')}
          error={
            'shippingProvider' in errors ? errors.apiKey?.message : undefined
          }
        />

        {!!config?.carriers.length && (
          <Controller
            name="enabledCarriers"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MultiSelect
                label="Allowed Carriers"
                placeholder="Search for a carrier"
                options={config.carriers.map((carrier) => ({
                  label: carrier.name,
                  value: carrier.id.toString(),
                  searchValue: carrier.name,
                }))}
                value={value.map((carrier) => ({
                  label: carrier.name,
                  value: carrier.id.toString(),
                  searchValue: carrier.name,
                }))}
                onChange={(selected) => {
                  onChange(
                    selected.map((selectedCarrier) =>
                      config.carriers.find(
                        ({ id }) => id.toString() === selectedCarrier.value,
                      ),
                    ),
                  );
                }}
                details="Carriers to select from when generating shipping labels."
                error={errors.enabledCarriers?.message}
              />
            )}
          />
        )}

        <div className="flex flex-row justify-end gap-2">
          <Button className="w-20" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="w-20"
            variant="primary"
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            {!isSubmitting ? 'Save' : <SpinnerIcon />}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function EasyPostConfig() {
  const { data: { easyPostConfig } = {} } = useIntegrationSettingsData();
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const [showForm, setShowForm] = useState(false);
  const description =
    easyPostConfig ?
      `EasyPost is ${easyPostConfig.isEnabled ? 'enabled.' : 'disabled.'}`
    : 'Connect an EasyPost account to generate shipping labels.';

  const connectHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;
    if (!easyPostConfig) {
      setShowForm(true);
    } else {
      saveChanges({
        kind: 'easyPostConfig',
        ...easyPostConfig,
        isEnabled: checked,
      }).catch(console.error);
    }
  };

  return (
    <>
      <IntegrationCard
        imgUrl="https://cdn.corso.com/img/easypost-1.png"
        title="EasyPost"
        description={description}
        worksWith={returnWarranty}
        action={
          <div className="flex flex-row items-center gap-2">
            <SwitchInput
              id="connect-easyPost"
              label="Connect a EasyPost account"
              labelVisuallyHidden
              name="EasyPost"
              checked={!!easyPostConfig?.isEnabled}
              onChange={connectHandler}
            />

            {easyPostConfig && (
              <IconAction.Button
                icon={Cog6ToothIcon}
                onClick={() => {
                  setShowForm(true);
                }}
                title="Edit EasyPost Configuration"
              />
            )}
          </div>
        }
      />

      <EasyPostConfigForm
        showForm={showForm}
        config={easyPostConfig}
        closeForm={() => setShowForm(false)}
      />
    </>
  );
}

function RechargeConfigForm({
  showForm,
  config,
  closeForm,
}: {
  showForm: boolean;
  config: IntegrationSettings['rechargeConfig'] | undefined;
  closeForm: () => void;
}) {
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,
  } = useForm<CrewMerchantUi.RechargeUpdate>({
    resolver: zodResolver(CrewMerchantUi.rechargeUpdateSchema),
    values:
      config ?
        {
          kind: 'rechargeConfig',
          ...config,
        }
      : {
          kind: 'rechargeConfig',
          isEnabled: true,
          apiKey: '',
        },
  });

  const closeHandler = () => {
    closeForm();
    reset();
  };

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit(
      (updates) =>
        saveChanges(updates)
          .then(() => closeHandler())
          .catch(console.error),
      console.error,
    )(event).catch(console.error);
  };

  return (
    <Modal
      show={showForm}
      onClose={closeHandler}
      title="Recharge Configuration"
      description="Configure Recharge to offer Shipping Protection to subscription customers."
    >
      <form className="flex flex-col gap-4" onSubmit={submitHandler}>
        <PasswordInput
          id="shipping-provider-api-key"
          label="API Key"
          required
          {...register('apiKey')}
          error={
            'rechargeConfig' in errors ? errors.apiKey?.message : undefined
          }
        />

        <div className="flex flex-row justify-end gap-2">
          <Button className="w-20" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="w-20"
            variant="primary"
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            {!isSubmitting ? 'Save' : <SpinnerIcon />}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function RechargeConfig() {
  const { data: { rechargeConfig } = {} } = useIntegrationSettingsData();
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const [showForm, setShowForm] = useState(false);
  const description =
    rechargeConfig ?
      `Recharge is ${rechargeConfig.isEnabled ? 'enabled.' : 'disabled.'}`
    : 'Connect a Recharge account.';

  const connectHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;
    if (!rechargeConfig) {
      setShowForm(true);
    } else {
      saveChanges({
        kind: 'rechargeConfig',
        ...rechargeConfig,
        isEnabled: checked,
      }).catch(console.error);
    }
  };

  return (
    <>
      <IntegrationCard
        imgUrl="https://cdn.corso.com/img/recharge-1.png"
        title="Recharge"
        description={description}
        worksWith={shippingProtection}
        action={
          <div className="flex flex-row items-center gap-2">
            <SwitchInput
              id="connect-recharge"
              label="Connect a Recharge account."
              labelVisuallyHidden
              name="Recharge"
              checked={!!rechargeConfig?.isEnabled}
              onChange={connectHandler}
            />

            {rechargeConfig && (
              <IconAction.Button
                icon={Cog6ToothIcon}
                onClick={() => {
                  setShowForm(true);
                }}
                title="Edit Recharge Configuration"
              />
            )}
          </div>
        }
      />

      <RechargeConfigForm
        showForm={showForm}
        config={rechargeConfig}
        closeForm={() => setShowForm(false)}
      />
    </>
  );
}

function SkioConfigForm({
  showForm,
  config,
  closeForm,
}: {
  showForm: boolean;
  config: IntegrationSettings['skioConfig'] | undefined;
  closeForm: () => void;
}) {
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,
  } = useForm<CrewMerchantUi.SkioConfigUpdate>({
    resolver: zodResolver(CrewMerchantUi.skioConfigUpdateSchema),
    values:
      config ?
        {
          kind: 'skioConfig',
          skioConfig: config,
        }
      : {
          kind: 'skioConfig',
          skioConfig: {
            accessToken: '',
            isEnabled: true,
          },
        },
  });

  const closeHandler = () => {
    closeForm();
    reset();
  };

  const { webhookUrl } = config ?? {};

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit(
      (updates) =>
        saveChanges(updates)
          .then(() => reset())
          .catch(console.error),
      console.error,
    )(event).catch(console.error);
  };

  return (
    <Modal
      show={showForm}
      onClose={closeHandler}
      title="Skio Configuration"
      description="Configure Skio to offer Shipping Protection to subscription customers."
    >
      <form className="flex flex-col gap-4" onSubmit={submitHandler}>
        <PasswordInput
          id="shipping-provider-api-key"
          label="API Key"
          required
          {...register('skioConfig.accessToken')}
          error={
            'skioConfig' in errors ?
              errors?.skioConfig?.accessToken?.message
            : undefined
          }
        />

        {webhookUrl && (
          <>
            <UrlInput
              id="webhook-url"
              label="Webhook URL"
              details="Add this URL to your Skio admin."
              value={webhookUrl}
              required
              readOnly
              disabled
              addon={{
                outsideEnd: (
                  // using `grid` on mobile and `flex` on desktop avoid apply `flex-grow` to each child
                  <div className="grid grid-cols-2 items-center gap-2 md:flex">
                    {/* // ? adjust to be styled as a button group  */}
                    <ClipboardButton onClick={() => webhookUrl} />
                  </div>
                ),
              }}
            />
            <TextInput
              id="webhook-token"
              label="Webhook Token"
              details="The token is provided, after inserting the above webhook URL in the Skio Admin."
              {...register('skioConfig.webhookToken')}
              error={
                'skioConfig' in errors ?
                  errors.skioConfig?.webhookToken?.message
                : undefined
              }
            />
          </>
        )}

        <div className="flex flex-row justify-end gap-2">
          <Button className="w-20" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="w-20"
            variant="primary"
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            {!isSubmitting ? 'Save' : <SpinnerIcon />}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function SkioConfig() {
  const { data: { skioConfig } = {} } = useIntegrationSettingsData();
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();

  const [showForm, setShowForm] = useState(false);
  const description =
    skioConfig ?
      `Skio is ${skioConfig.isEnabled ? 'enabled.' : 'disabled.'}`
    : 'Connect a Skio account.';

  const connectHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;
    if (!skioConfig) {
      setShowForm(true);
    } else {
      saveChanges({
        kind: 'skioConfig',
        skioConfig: { ...skioConfig, isEnabled: checked },
      }).catch(console.error);
    }
  };

  return (
    <>
      <IntegrationCard
        imgUrl="https://cdn.corso.com/img/skio.png"
        title="Skio"
        description={description}
        worksWith={shippingProtection}
        action={
          <div className="flex flex-row items-center gap-2">
            <SwitchInput
              id="connect-skio"
              label="Connect a Skio account."
              labelVisuallyHidden
              name="Skio"
              checked={!!skioConfig?.isEnabled}
              onChange={connectHandler}
            />

            {skioConfig && (
              <IconAction.Button
                icon={Cog6ToothIcon}
                onClick={() => {
                  setShowForm(true);
                }}
                title="Edit Skio Configuration"
              />
            )}
          </div>
        }
      />

      <SkioConfigForm
        showForm={showForm}
        config={skioConfig}
        closeForm={() => setShowForm(false)}
      />
    </>
  );
}

function StatusForm({
  showForm,
  closeForm,
  values,
}: {
  showForm: boolean;
  closeForm: () => void;
  values: IntegrationSettings['statusConfig'] | undefined;
}) {
  const { mutateAsync: saveChanges } = useIntegrationSettingsUpdate();
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,
  } = useForm<CrewMerchantUi.StatusConfigUpdate>({
    resolver: zodResolver(CrewMerchantUi.statusConfigUpdateSchema),
    values: {
      kind: 'statusConfig',
      statusConfig: values ?? {
        idFromPlatform: '',
      },
    },
  });

  const closeHandler = () => {
    closeForm();
    reset();
  };

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit(
      (updates) => saveChanges(updates).then(closeHandler).catch(console.error),
      console.error,
    )(event).catch(console.error);
  };

  return (
    <Modal
      show={showForm}
      onClose={closeHandler}
      title="Status Accounts"
      description=""
    >
      <form className="flex flex-col gap-6" onSubmit={submitHandler}>
        <TextInput
          id="id-from-platform"
          label="Shopify Domain"
          placeholder="carl.myshopify.com"
          required
          {...register('statusConfig.idFromPlatform')}
          error={
            'statusConfig' in errors ?
              errors.statusConfig?.idFromPlatform?.message
            : undefined
          }
        />
        {values?.startReturnUrl && (
          <div className="grow">
            <UrlInput
              id="customer-portal"
              label="Returns and Warranty Portal"
              details="Provide this link to your Status representative to direct users to the returns and warranty portal."
              value={values.startReturnUrl} // maybe someday allow editing the slug in the url
              required
              readOnly
              disabled
              addon={{
                outsideEnd: (
                  // using `grid` on mobile and `flex` on desktop avoid apply `flex-grow` to each child
                  <div className="grid grid-cols-2 items-center gap-2 md:flex">
                    {/* // ? adjust to be styled as a button group  */}
                    <ClipboardButton
                      onClick={() => values.startReturnUrl ?? ''}
                    />
                  </div>
                ),
              }}
            />
          </div>
        )}

        <div className="flex flex-row justify-end gap-2">
          <Button className="w-20" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="w-20"
            variant="primary"
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            {!isSubmitting ? 'Save' : <SpinnerIcon />}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function StatusConfig() {
  const { data: { statusConfig } = {} } = useIntegrationSettingsData();
  const { mutate: saveChanges } = useIntegrationSettingsUpdate();

  const statusEnabled = !!statusConfig;

  const [showForm, setShowForm] = useState(false);

  const disconnectHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;

    if (!checked && statusConfig) {
      saveChanges({
        kind: 'statusConfig',
        statusConfig: { ...statusConfig, disconnect: true },
      });
    } else {
      setShowForm(true);
    }
  };

  const description =
    statusEnabled ? `Status is connected.` : (
      <div className="space-y-1">
        <p>
          {' '}
          Connect A Status account.{' '}
          <a
            className="mt-2 text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
            href="https://www.statusaccounts.com/"
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
        </p>
      </div>
    );

  return (
    <>
      <IntegrationCard
        imgUrl="https://cdn.corso.com/img/status-4.png"
        title="Status"
        description={description}
        worksWith={returnWarrantyProtect}
        action={
          <div className="flex flex-row items-center gap-2">
            <SwitchInput
              id="status-switch"
              label="Enable or disable Status Accounts"
              labelVisuallyHidden
              onChange={disconnectHandler}
              defaultChecked={statusEnabled}
            />
            {statusEnabled && (
              <IconAction.Button
                icon={Cog6ToothIcon}
                onClick={() => setShowForm(true)}
                title="Edit Status Configuration"
              />
            )}
          </div>
        }
      />

      <StatusForm
        showForm={showForm}
        values={statusConfig}
        closeForm={() => setShowForm(false)}
      />
    </>
  );
}

export default function IntegrationsSettings() {
  const { isReturnOrWarrantyEnabled } = useEnabledClaimType();

  return (
    <Page title="Integrations">
      <div className="flex flex-col gap-4">
        <IntegrationSection title="E-Commerce Platform">
          <AppSubscription />
        </IntegrationSection>

        {isReturnOrWarrantyEnabled && (
          <>
            <IntegrationSection title="Shipping Provider">
              <VesylConfig />
              <EasyPostConfig />
            </IntegrationSection>

            <IntegrationSection title="WMS Platform">
              <ShipHero />
              <ShipBob />
              <BlueBox />
              <XbFulfillment />
            </IntegrationSection>
          </>
        )}

        <IntegrationSection title="Customer Experience">
          <GorgiasConfig />
          <GladlyConfig />
          <ZowieConfig />
          <KustomerConfig />
        </IntegrationSection>

        {isReturnOrWarrantyEnabled && (
          <IntegrationSection title="Email & SMS Providers">
            <KlaviyoConfig />
            <SendlaneConfig />
            <AttentiveConfig />
            <PostscriptConfig />
          </IntegrationSection>
        )}

        <IntegrationSection title="Subscription Management">
          <SkioConfig />
          <RechargeConfig />
        </IntegrationSection>

        <IntegrationSection title="Other Integrations">
          {isReturnOrWarrantyEnabled && <StripeConfig />}
          <StatusConfig />

          <BrijConfig />
        </IntegrationSection>
      </div>
    </Page>
  );
}
