import { useQuery } from '@tanstack/react-query';
import api from '~/api';
import { useStoreId } from './useStoreId';

function useQueryKey() {
  const storeId = useStoreId();
  return ['factValues', storeId] as const;
}

export function useFactValues() {
  const queryKey = useQueryKey();
  return useQuery({
    queryKey,
    throwOnError: false,
    queryFn: ({ queryKey: [, storeId] }) => api.store(storeId).factValues.get(),
  });
}
