import { PencilIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from '~/api';
import Address from '~/components/Address';
import Button from '~/components/Button';
import Card from '~/components/Card';
import ClipboardButton from '~/components/ClipboardButton';
import { NumberInput, TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import Modal from '~/components/Modal';
import Skeleton from '~/components/Skeleton';
import SpinnerIcon from '~/components/SpinnerIcon';
import { MultiSelect } from '~/components/ui/MultiSelect';
import {
  useClaimTagOptions,
  useClaimTags,
  useClaimTagsUpsert,
} from '~/hooks/useClaimTags';
import { useStoreId } from '~/hooks/useStoreId';
import {
  useClaimReviewContext,
  useInvalidateClaimReview,
} from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  ClaimUpdateCustomerInfo,
  claimUpdateCustomerInfo,
  ClaimUpdateFees,
  claimUpdateFees,
} from '~/types';
import ClaimShipmentsDisplay from './shipments.ts/ClaimShipment';

function EditCustomerInfo({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const { claimReview } = useClaimReviewContext();

  const claim = claimReview.watch('claim');

  const invalidateClaim = useInvalidateClaimReview();
  const storeId = useStoreId();
  const { userFullName } = useMerchantContext();

  const { mutateAsync: updateCustomerInfo, isPending } = useMutation({
    mutationFn: (customerInfoUpdate: ClaimUpdateCustomerInfo) =>
      api
        .store(storeId)
        .claim(`${claim.id}`, userFullName)
        .updateCustomerInfo(customerInfoUpdate),
    onSuccess: () => invalidateClaim(),
  });

  const formId = 'edit-claim-form';
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    resolver: zodResolver(claimUpdateCustomerInfo),
    defaultValues: {
      id: claim.id,
      shippingAddress: claim.shippingAddress,
      customerEmail: claim.customerEmail,
    } satisfies ClaimUpdateCustomerInfo,
  });

  const closeAndReset = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      title="Edit Customer"
      key={claim.id}
      show={show}
      onClose={closeAndReset}
      actions={
        <>
          <Button onClick={closeAndReset}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={isPending}
            loading={isPending}
          >
            Save
          </Button>
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(
          async ({ id, customerEmail, shippingAddress }) => {
            await updateCustomerInfo({
              id,
              customerEmail,
              shippingAddress,
            });
            onClose();
          },
        )}
        className="flex flex-col gap-4"
      >
        <TextInput
          id="first-name"
          label="First Name"
          autoComplete="first-name"
          required
          {...register('shippingAddress.firstName')}
          error={errors.shippingAddress?.firstName?.message}
        />

        <TextInput
          id="last-name"
          label="Last Name"
          autoComplete="last-name"
          required
          {...register('shippingAddress.lastName')}
          error={errors.shippingAddress?.lastName?.message}
        />

        <TextInput
          id="email"
          label="Email"
          autoComplete="email"
          required
          {...register('customerEmail')}
          error={errors.customerEmail?.message}
        />

        <TextInput
          id="phone"
          label="Phone"
          autoComplete="tel"
          required
          {...register('shippingAddress.phone')}
          error={errors.shippingAddress?.phone?.message}
        />

        <TextInput
          id="shipping-address-line1"
          label="Street Address"
          autoComplete="address-line1"
          required
          {...register('shippingAddress.line1')}
          error={errors.shippingAddress?.line1?.message}
        />

        <TextInput
          id="shipping-address-line2"
          label="Street Address Line 2"
          autoComplete="address-line2"
          {...register('shippingAddress.line2')}
          error={errors.shippingAddress?.line2?.message}
        />

        <TextInput
          id="shipping-address-city"
          label="City"
          autoComplete="address-level2"
          required
          {...register('shippingAddress.city')}
          error={errors.shippingAddress?.city?.message}
        />

        <TextInput
          id="shipping-address-state-or-province"
          label="State or Province Code"
          autoComplete="address-level1"
          required
          {...register('shippingAddress.stateOrProvinceCode')}
          error={errors.shippingAddress?.stateOrProvinceCode?.message}
        />

        <TextInput
          id="shipping-address-postal-code"
          label="Postal Code"
          autoComplete="postal-code"
          required
          {...register('shippingAddress.postalCode')}
          error={errors.shippingAddress?.postalCode?.message}
        />

        <TextInput
          id="shipping-address-country"
          label="Country Code"
          autoComplete="country"
          required
          {...register('shippingAddress.countryCode')}
          error={errors.shippingAddress?.countryCode?.message}
        />
      </form>
    </Modal>
  );
}

function EditFees({ show, onClose }: { show: boolean; onClose: () => void }) {
  const { claimReview } = useClaimReviewContext();

  const claim = claimReview.watch('claim');

  const currentRefundHandlingFee = claim.feeApplied;

  const invalidateClaim = useInvalidateClaimReview();

  const storeId = useStoreId();
  const {
    userFullName,
    storeUser: {
      store: { currencyCode, currencySymbol },
    },
  } = useMerchantContext();

  const { mutateAsync: updateClaimFees, isPending } = useMutation({
    mutationFn: (claimFeesUpdate: ClaimUpdateFees) =>
      api
        .store(storeId)
        .claim(`${claim.id}`, userFullName)
        .updateFees(claimFeesUpdate),
    onSuccess: () => invalidateClaim(),
  });

  const formId = 'edit-claim-fees-form';
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    resolver: zodResolver(claimUpdateFees),
    defaultValues: {
      feeApplied: currentRefundHandlingFee,
    } satisfies ClaimUpdateFees,
  });

  const closeAndReset = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      title="Edit Fees"
      show={show}
      onClose={closeAndReset}
      actions={
        <>
          <Button onClick={closeAndReset}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={isPending}
            loading={isPending}
          >
            Save
          </Button>
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(async ({ feeApplied }) => {
          await updateClaimFees({
            feeApplied,
          });
          onClose();
        })}
        className="flex flex-col gap-4"
      >
        <NumberInput
          id="refund-handling-fee"
          label="Refund Handling Fee"
          step="0.01"
          details="The amount that will be deducted from the refund amount when the claim is finalized."
          addon={{
            /**
             *  not ideal as symbol placement depends on locale
             * and in some locales the symbol is not used at all
             * e.g. es-MX, es-AR the Euro symbol is not used
             * but instead display prefixed with `EUR`
             */
            insideStart: currencySymbol,
            insideEnd: currencyCode,
          }}
          required
          {...register('feeApplied', {
            valueAsNumber: true,
          })}
          error={errors.feeApplied?.message}
        />
      </form>
    </Modal>
  );
}

function SmallGrayText({ text }: { text: string }) {
  return <p className="text-xs text-corso-gray-500">{text}</p>;
}

function CustomerDisplay() {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.watch('claim');

  const { shippingAddress } = claim;
  const { firstName, lastName, phone } = shippingAddress;

  const customerName = `${firstName} ${lastName}`;

  const [showEditClaim, setShowEditClaim] = useState(false);
  const closeEditClaim = () => setShowEditClaim(false);

  return (
    <Card>
      <div className="flex flex-col gap-1">
        {/* Title and Edit Button */}
        <div className="flex items-center justify-between">
          <Card.Title>Customer</Card.Title>
          <IconAction.Button
            icon={PencilIcon}
            variant="ghost"
            iconSize="sm"
            title="Edit Customer"
            onClick={() => setShowEditClaim(true)}
          />
        </div>
        <SmallGrayText text={customerName} />
      </div>

      <div className="flex flex-col gap-1">
        <Card.Title>Contact information</Card.Title>
        <div className="flex items-center justify-between">
          <SmallGrayText text={claim.customerEmail} />
          <ClipboardButton
            onClick={() => claim.customerEmail}
            variant="ghost"
            iconSize="sm"
          />
        </div>
        <SmallGrayText text={phone ?? 'No phone'} />
      </div>

      <div className="flex flex-col gap-1">
        <Card.Title>Shipping address</Card.Title>
        <Address address={shippingAddress} />
      </div>

      {claim.noteToCustomer && (
        <div className="flex flex-col gap-1">
          <Card.Title>Note to Customer</Card.Title>
          <SmallGrayText text={claim.noteToCustomer} />
        </div>
      )}

      {/* edit claim */}
      <EditCustomerInfo show={showEditClaim} onClose={closeEditClaim} />
    </Card>
  );
}

function ClaimTagDisplaySkeleton() {
  return (
    <Card>
      <Card.Title>Tags</Card.Title>
      <Skeleton.Rectangle height="4rem" width="100%" />
    </Card>
  );
}

function ClaimTagDisplay() {
  const { data: tagOptions, isPending: isOptionsPending } =
    useClaimTagOptions();
  const { data: currentTags, isPending: isCurrentPending } = useClaimTags();
  const { mutateAsync: upsertTags, isPending: isUpsertPending } =
    useClaimTagsUpsert();

  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (!currentTags) return;
    setTags(currentTags);
  }, [currentTags]);

  return (
    <Skeleton
      isLoading={isOptionsPending || isCurrentPending}
      skeleton={ClaimTagDisplaySkeleton}
    >
      <Card>
        <MultiSelect
          label={
            <div className="flex items-center gap-2">
              <Card.Title>Tags</Card.Title>
              {isUpsertPending && <SpinnerIcon className="h-3 w-3" />}
            </div>
          }
          placeholder="Add tags"
          options={(tagOptions ?? []).map((tag) => ({
            label: tag,
            value: tag,
          }))}
          value={tags.map((tag) => ({
            label: tag,
            value: tag,
          }))}
          onChange={(changedTags) => {
            const tagValues = changedTags.map((tag) => tag.value);
            setTags(tagValues);
            upsertTags(tagValues).catch((error) => console.error(error));
          }}
          creatable
        />
      </Card>
    </Skeleton>
  );
}

function ClaimFeesDisplay() {
  const { claimReview } = useClaimReviewContext();

  const claim = claimReview.watch('claim');
  const showClaimFeesDisplay =
    claim.reviewLineItems.Refund.length > 0 &&
    claim.claimRollup.code !== 'Completed';

  const [showEditFees, setShowEditFees] = useState(false);
  const closeEditFees = () => setShowEditFees(false);

  if (!showClaimFeesDisplay) return null;
  return (
    <Card>
      {/* edit customer */}
      <p className="flex items-center justify-between text-sm font-medium">
        <span className="text-sm font-bold text-corso-gray-900 opacity-75">
          Handling Fee
        </span>
        <IconAction.Button
          icon={PencilIcon}
          variant="ghost"
          iconSize="sm"
          title="Edit Handling Fee"
          onClick={() => setShowEditFees(true)}
        />
      </p>

      {/* edit claim */}
      <EditFees show={showEditFees} onClose={closeEditFees} />
    </Card>
  );
}

export default function ClaimDetail() {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.watch('claim');

  // TODO: add claim display, indicating all the claims for a specific order, this could potentially be part of the Customer
  return (
    <div className="flex flex-col gap-4" key={claim.id}>
      <CustomerDisplay />
      <ClaimFeesDisplay />
      <ClaimTagDisplay />
      <ClaimShipmentsDisplay />
    </div>
  );
}
