import {
  CrewClaimTypeEnum,
  CrewMerchantUi,
  InventoryBehavior,
  ShipmentMethod,
} from 'corso-types';
import { z } from 'zod';

/** Helper to more easily define [context](https://react.dev/reference/react#context-hooks) with a discriminated union on the loading state to a partial or the object. */
export type LoadingContext<T> =
  | ({ isLoading: true } & Partial<T>)
  | ({ isLoading: false } & T);

export type Variant = CrewMerchantUi.StoreProduct['variants'][number];
export type SelectedVariant = Omit<Variant, 'quantity'> & { quantity: number };

export type StoreRule = CrewMerchantUi.StoreRule;
export const storeRule = CrewMerchantUi.storeRuleSchema;
export type StoreRuleCreate = CrewMerchantUi.StoreRuleCreate;
export const storeRuleCreate = CrewMerchantUi.storeRuleCreateSchema;
export type StoreRuleUpdate = CrewMerchantUi.StoreRuleUpdate;
export const storeRuleUpdate = CrewMerchantUi.storeRuleUpdateSchema;

export type ClaimLineItemCustomField = CrewMerchantUi.AppliedCustomField;
export type CustomField = CrewMerchantUi.CustomField;
export const customField = CrewMerchantUi.customFieldSchema;

export type CustomFieldCreate = CrewMerchantUi.CustomFieldCreate;
export const { customFieldCreateSchema } = CrewMerchantUi;
export type CustomFieldUpdate = CrewMerchantUi.CustomFieldUpdate;
export const { customFieldUpdateSchema } = CrewMerchantUi;

export const returnLocation = CrewMerchantUi.returnLocationSchema;
export type ReturnLocation = CrewMerchantUi.ReturnLocation;

export const returnLocationCreate = CrewMerchantUi.returnLocationCreateSchema;
export type ReturnLocationCreate = CrewMerchantUi.ReturnLocationCreate;

export const claimReason = CrewMerchantUi.claimReasonSchema;
export type ClaimReason = CrewMerchantUi.ClaimReason;
export const claimReasonCreate = CrewMerchantUi.claimReasonCreateSchema;
export type ClaimReasonCreate = CrewMerchantUi.ClaimReasonCreate;
export const claimReasonUpdate = CrewMerchantUi.claimReasonUpdateSchema;
export type ClaimReasonUpdate = CrewMerchantUi.ClaimReasonUpdate & {
  category: ClaimReason['category'];
};

export const { storeUserSignUpAndOnboardSchema, storeUserSignUpSchema } =
  CrewMerchantUi;

export type Shipment = CrewMerchantUi.ClaimShipment;
export type ClaimShipmentQuoteAndCreate =
  CrewMerchantUi.ClaimShipmentCreateAndBuyRequest;

export type ClaimShipmentCreate = CrewMerchantUi.ClaimReturnShipmentCreate;
export const claimShipmentCreate =
  CrewMerchantUi.claimReturnShipmentCreateSchema;
export type ClaimShipmentQuote = CrewMerchantUi.ClaimReturnShipmentQuoteRequest;
export const claimShipmentQuote = CrewMerchantUi.claimReturnShipmentQuoteSchema;
export type ClaimShipmentQuoteResponse =
  CrewMerchantUi.ClaimReturnShipmentQuoteResponse;

export const shipmentTypeEnums = Object.values(ShipmentMethod);
export const shipmentTypeLabels = {
  [ShipmentMethod.label]: 'Shipping Label',
  [ShipmentMethod.packingSlip]: 'Packing Slip',
} as const satisfies Record<ShipmentMethod, string>;

export const inventoryBehaviorLabels = {
  [InventoryBehavior.bypass]: 'Do Not Decrement Inventory',
  [InventoryBehavior.decrementIgnoringPolicy]:
    'Decrement Inventory (Ignore Policies)',
  [InventoryBehavior.decrementObeyingPolicy]:
    'Decrement Inventory (Obey Policies)',
} satisfies Record<InventoryBehavior, string>;

export type ClaimUpdateCustomerInfo = CrewMerchantUi.ClaimUpdateCustomerInfo;
export const claimUpdateCustomerInfo =
  CrewMerchantUi.claimUpdateCustomerInfoSchema;

export type RegistrationUpdate = CrewMerchantUi.RegistrationUpdate;
export const registrationUpdate = CrewMerchantUi.registrationUpdateSchema;

export type ClaimUpdateFees = CrewMerchantUi.ClaimUpdateRefundHandlingFee;
export const claimUpdateFees =
  CrewMerchantUi.claimUpdateRefundHandlingFeeSchema;

export const ACCESS_CODE_KEY = CrewMerchantUi.MERCHANT_APP_JWT_QUERY_KEY;

export type StoreFulfillmentLocation = CrewMerchantUi.StoreFulfillmentLocation;

export type StoreUser = CrewMerchantUi.StoreUser;

export type StoreUserCreate = CrewMerchantUi.StoreUserCreate;
export type StoreUserUpdate = CrewMerchantUi.StoreUserUpdate;
export const { storeUserCreateSchema, storeUserUpdateSchema, storeUserSchema } =
  CrewMerchantUi;
export type MerchantOnboardFormValues = CrewMerchantUi.MerchantOnboard;

export type ClaimReasonGroup = CrewMerchantUi.ClaimReasonGroup;
export type ClaimReasonGroupCreate = CrewMerchantUi.ClaimReasonGroupCreate;
export type ClaimReasonGroupUpdate = CrewMerchantUi.ClaimReasonGroupUpdate;
export const claimReasonGroup = CrewMerchantUi.claimReasonGroupSchema;

export type ClaimType = CrewClaimTypeEnum;

export const claimReasonGroupUpdate =
  CrewMerchantUi.claimReasonGroupUpdateSchema;

export const claimReasonGroupCreate =
  CrewMerchantUi.claimReasonGroupCreateSchema;
export type ConfigSettings = CrewMerchantUi.SettingsConfig;
export type ThemeSettings = CrewMerchantUi.SettingsTheme;
export type IntegrationSettings = CrewMerchantUi.SettingsIntegration;

export type ProductGroup = CrewMerchantUi.StoreProductGroup;
export const productGroupSchema = CrewMerchantUi.storeProductGroupSchema;

export type ProductGroupCreate = CrewMerchantUi.StoreProductGroupCreate;
export const productGroupCreateSchema =
  CrewMerchantUi.storeProductGroupCreateSchema;

export type ProductTag = CrewMerchantUi.StoreProductGroup['tags'][number];
export type ProductType = CrewMerchantUi.StoreProductGroup['types'][number];
export type ProductsInGroup = CrewMerchantUi.StoreProductsForGroup[];

export const { merchantOnboardSchema } = CrewMerchantUi;

export type ApiClientResp = CrewMerchantUi.ApiClientResp;
export const { apiClientRespSchema } = CrewMerchantUi;

export const productTagSchema =
  CrewMerchantUi.storeProductGroupSchema.shape.tags.element;
export const productTypeSchema = CrewMerchantUi.storeProductGroupSchema.shape;

const {
  email,
  notifications,
  returns, // applies to all resolution methods
  refunds,
  giftCards, // displayed as store credit
  variantExchanges,
  warranties,
  registrations,
  shippingProtection,
} = CrewMerchantUi.settingsConfigSchema.shape;

export const themeSettingsFormSchema = CrewMerchantUi.settingsThemeSchema;
export type ThemeSettingsFormValues = CrewMerchantUi.SettingsTheme;
export const emailSettingsFromSchema = email;
export type EmailSettingsFormValues = z.infer<typeof emailSettingsFromSchema>;
export const integrationSettingsFormSchema =
  CrewMerchantUi.settingsIntegrationUpdateSchema;
export type IntegrationSettingsFormValues = z.infer<
  typeof integrationSettingsFormSchema
>;

export const notificationSettingsFormSchema = notifications;
export type NotificationSettingsFormValues = z.infer<
  typeof notificationSettingsFormSchema
>;
export const warrantySettingsFormSchema = warranties;

export type WarrantySettingsFormValues = z.infer<
  typeof warrantySettingsFormSchema
>;

export const RegistrationsFormSchema = registrations.extend({
  theme: themeSettingsFormSchema.pick({
    identifyProductUrl: true,
    identifyProductUrlButtonText: true,
    identifyProductDetailText: true,
    identifyProductFormPlaceholder: true,
    orderLookupRegistrationText: true,
  }),
});

export type RegistrationFormValues = z.infer<typeof RegistrationsFormSchema>;

export const shipProtectSettingsFormSchema = shippingProtection;
export type ShipProtectSettingsFormValues = z.infer<
  typeof shipProtectSettingsFormSchema
>;

export const returnSettingsFormSchema = z.object({
  returns,
  refunds: z
    .discriminatedUnion('isRefundsEnabled', [
      z.object({
        isRefundsEnabled: z.literal(true),
        refundValidityDays: z.number().positive(),
      }),
      z.object({
        isRefundsEnabled: z.literal(false),
        refundValidityDays: z.literal(0),
      }),
    ])
    .and(refunds.omit({ refundValidityDays: true })),

  giftCards,
  // * have to separate this out because Zod doesn't support nested discriminated unions
  giftCardsControl: z.discriminatedUnion('isGiftCardEnabled', [
    z.object({
      isGiftCardEnabled: z.literal(true),
      giftCardValidityDays: z.number().positive(),
    }),
    z.object({
      isGiftCardEnabled: z.literal(false),
      giftCardValidityDays: z.literal(0),
    }),
  ]),
  variantExchanges: z
    .discriminatedUnion('isExchangeEnabled', [
      z.object({
        isExchangeEnabled: z.literal(true),
        variantExchangeValidityDays: z.number().positive(),
      }),
      z.object({
        isExchangeEnabled: z.literal(false),
        variantExchangeValidityDays: z.literal(0),
      }),
    ])
    .and(variantExchanges.omit({ variantExchangeValidityDays: true })),
});
export type ReturnSettingsFormValues = z.infer<typeof returnSettingsFormSchema>;

// TODO make `DEFAULT` into `default` instead to match shadcn/ui variant pattern
export type UIStatusVariant =
  | 'DEFAULT'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info';

export type UpsertFormProps<
  Update extends { id: number | string },
  Create = Omit<Update, 'id'>,
> = {
  show: boolean;
  onClose: () => void;
} & (
  | {
      onSubmit: (update: Update) => void;
      values: Update;
    }
  | {
      onSubmit: (create: Create) => void;
      values?: never;
    }
);
