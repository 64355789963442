import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '~/api';
import { useClaimReviewContext } from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { useStoreId } from './useStoreId';

function useClaimTagOptionsQueryKey() {
  const storeId = useStoreId();
  return ['claimTagOptions', storeId] as const;
}

/** Available tags across the store. */
export function useClaimTagOptions() {
  const queryKey = useClaimTagOptionsQueryKey();
  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) =>
      api.store(storeId).claimTagOptions.list(),
    select: (data) => data.map((tag) => tag.name),
  });
}

function useClaimTagsQueryKey() {
  const storeId = useStoreId();
  const { claimReview } = useClaimReviewContext();
  const claimId = claimReview.watch('claim.id');
  const { userFullName } = useMerchantContext();

  return ['claimTags', storeId, `${claimId}`, userFullName] as const;
}

/** Current tags for the claim. */
export function useClaimTags() {
  const queryKey = useClaimTagsQueryKey();
  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId, claimId, loggedInUser] }) =>
      api.store(storeId).claim(claimId, loggedInUser).tags.list(),
    select: (data) => data.map((tag) => tag.name),
  });
}

/** Upsert tags for a specific claim. */
export function useClaimTagsUpsert() {
  const queryKey = useClaimTagsQueryKey();
  const queryClient = useQueryClient();

  const [, storeId, claimId, loggedInUser] = queryKey;

  return useMutation({
    mutationKey: queryKey,
    mutationFn: (tags: string[]) =>
      api
        .store(storeId)
        .claim(claimId, loggedInUser)
        .tags.upsert(tags.map((name) => ({ name }))),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onMutate: () => queryClient.cancelQueries({ queryKey }), // stop any in-flight queries
  });
}
