import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import PageStatus from '~/components/PageStatus';
import { Button } from '~/components/ui/primitives/Button';
import { useStoreId } from '~/hooks/useStoreId';

export default function NotFound() {
  const storeId = useStoreId();
  return (
    <PageStatus
      title="Page Not Found"
      detail="Sorry, we couldn't find the page you're looking for."
      icon={faQuestionCircle}
    >
      <Button asChild className="w-full">
        <NavLink to={`/${storeId}`} replace reloadDocument>
          Go to Home
        </NavLink>
      </Button>
    </PageStatus>
  );
}
