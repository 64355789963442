import { useQuery } from '@tanstack/react-query';
import api from '~/api';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { useToast } from './useToast';

export function useDashboardQuery(dashboardId: string) {
  const {
    storeUser: { storeId },
    user: { firstName, lastName, email },
  } = useMerchantContext();

  const toast = useToast();

  return useQuery({
    throwOnError: false,
    queryKey: [
      'superset',
      firstName,
      lastName,
      email,
      dashboardId,
      `${storeId}`,
    ],
    queryFn: () =>
      api
        .store(`${storeId}`)
        .dashboard.load({ email, firstName, lastName, dashboardId })
        .then((data) => data)
        .catch(() => {
          toast.show('Failed to load dashboard. Please try another option.'); // TODO remove toast for error state instead
          return null;
        }),
  });
}

export function useLoadDashboardOptions() {
  const {
    storeUser: { storeId },
  } = useMerchantContext();

  const toast = useToast();

  return useQuery({
    throwOnError: false,
    queryKey: ['dashboards', `${storeId}`],
    queryFn: () =>
      api
        .store(`${storeId}`)
        .dashboard.list()
        .then((data) => data)
        .catch(() => {
          toast.show('Failed to load dashboard. Please try another option.'); // TODO remove toast for error state instead
          return null;
        }),
  });
}
