import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Pegasus } from 'corso-types';
import { useState } from 'react';
import { z } from 'zod';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import EmptyStateAction from '~/components/EmptyStateAction';
import { SwitchInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import Page from '~/components/Page';
import Skeleton from '~/components/Skeleton';
import { useSearchParam } from '~/hooks/useSearchParam';
import {
  useStoreRuleDelete,
  useStoreRules,
  useToggleStoreRule,
} from '~/hooks/useStoreRules';
import { StoreRule } from '~/types';
import { StoreRuleDisplay } from './StoreRuleDisplay';
import { displayName, eventTypeDescription } from './StoreRuleForm';
import { getEventTypeIcon } from './StoreRulesOverview';

function StoreRuleDisplaySkeleton() {
  return (
    <ul className="flex flex-col gap-2">
      <li className="flex justify-between">
        <Skeleton.Rectangle width="100%" height="2rem" />
        <div>
          <Skeleton.Rectangle width="3rem" height="2rem" />
          <Skeleton.Rectangle width="3rem" height="2rem" />
        </div>
      </li>
    </ul>
  );
}

function StoreRuleDelete({ storeRule }: { storeRule: StoreRule }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const { mutate: storeRuleDelete } = useStoreRuleDelete();

  return (
    <>
      <ConfirmModal
        show={showConfirm}
        title="Delete Rule"
        prompt={`Are you sure you want to delete "${storeRule.name}"?`}
        cancelText="Cancel"
        confirmText="Delete"
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => {
          storeRuleDelete(storeRule.id);
          setShowConfirm(false);
        }}
      />
      <IconAction.Button
        title={`Delete ${storeRule.name} Rule`}
        icon={TrashIcon}
        onClick={() => setShowConfirm(true)}
      />
    </>
  );
}

export default function StoreRulesList() {
  const eventTypeParam = useSearchParam('eventType');
  const { data: storeRules = [], isLoading } = useStoreRules();
  const toggleRule = useToggleStoreRule();

  const eventType = z
    .nativeEnum(Pegasus.EventType)
    .nullable()
    .parse(eventTypeParam);

  const filteredStoreRules = storeRules
    .filter(
      (storeRule) => !eventType || storeRule.rule.event.type === eventType,
    )
    .sort((a, b) => a.name.localeCompare(b.name)) // alphabetical
    .sort((a, b) => b.rule.priority - a.rule.priority) // descending priority; higher will execute first
    .sort((a, b) => {
      // only those that are enabled will execute and apply
      if (a.isEnabled === b.isEnabled) return 0; // keep the order if both are enabled or disabled
      return a.isEnabled ? -1 : 1; // a before b if a is enabled
    });

  return (
    <Page
      backAction
      title={`${eventType ? displayName.eventType[eventType] : 'All'} Automations`}
      subtitle="Rules are shown in the order they will apply."
      primaryAction={{
        content: 'Create Rule',
        to: {
          pathname: '../create',
          search: eventType ? `?eventType=${eventType}` : '',
        },
      }}
    >
      <Card>
        <Skeleton skeleton={StoreRuleDisplaySkeleton} isLoading={isLoading}>
          {/* // TODO error state */}
          {!filteredStoreRules.length ?
            <EmptyStateAction.Link
              to={{
                pathname: '../create',
                search: eventType ? `?eventType=${eventType}` : '',
              }}
              icon={getEventTypeIcon(eventType)}
              label={
                eventType ?
                  `Create Rule to ${displayName.eventType[eventType]}`
                : 'Create Automation Rule'
              }
              description={
                eventType ?
                  `Looks like you don't have any automations rules to ${displayName.eventType[
                    eventType
                  ].toLowerCase()} yet. ${eventTypeDescription[eventType]}`
                : 'Automate the review process at different stages of the claim lifecycle.'
              }
            />
          : <ul className="flex flex-col gap-2">
              {filteredStoreRules.map((storeRule) => (
                <li key={storeRule.id}>
                  <StoreRuleDisplay
                    storeRule={storeRule}
                    actions={
                      <div className="flex items-center gap-2">
                        <SwitchInput
                          id="isRuleEnabled"
                          label="Enabled"
                          labelVisuallyHidden
                          checked={storeRule.isEnabled}
                          onChange={() => toggleRule(storeRule)}
                        />
                        <IconAction.Link
                          title={`Edit ${storeRule.name} Rule`}
                          icon={PencilIcon}
                          to={`../edit/${storeRule.id}`}
                        />
                        <StoreRuleDelete storeRule={storeRule} />
                      </div>
                    }
                  />
                </li>
              ))}
            </ul>
          }
        </Skeleton>
      </Card>
    </Page>
  );
}
