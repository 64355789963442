import DetailCard from '~/components/DetailCard';
import { useStoreId } from '~/hooks/useStoreId';
import { useClaimReviewContext } from '~/providers/ClaimReviewProvider';

export function RegistrationSummary() {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.watch('claim');
  const storeId = useStoreId();

  const { originalStoreOrder } = claim;
  const { registration } = originalStoreOrder;

  if (!registration) return null;

  const { externalId } = registration;

  return (
    <DetailCard
      linkTo={`/${storeId}/registrations/${registration.id}`}
      externalId={externalId}
      title="Registration"
    />
  );
}
