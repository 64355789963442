import { forwardRef, ReactNode } from 'react';
import { Link, LinkProps, NavLink, NavLinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import SpinnerIcon from './SpinnerIcon';

type ButtonVariant =
  | 'DEFAULT'
  | 'primary'
  | 'primaryLight'
  | 'success'
  | 'successLight'
  | 'danger'
  | 'dangerLight'
  | 'dangerOutline'
  | 'text'
  | 'warning';

// TODO outline variant for all kinds
export const variantStyles = {
  text: 'text-primary-on-container hover:underline shadow-none ring-0 text-corso-blue-800 hover:text-corso-blue-600 disabled:text-corso-gray-600 disabled:hover:no-underline',
  DEFAULT:
    'text-corso-gray-800 bg-white ring-corso-gray-300 hover:bg-corso-gray-100 focus-visible:outline-blue-600 disabled:bg-corso-gray-200 disabled:text-corso-gray-900', // outline matches primary, to be similar to default button focus styling
  primary:
    'text-white bg-corso-blue-600 ring-corso-blue-700 hover:bg-corso-blue-700 focus-visible:outline-corso-blue-600 disabled:bg-corso-gray-400 disabled:ring-corso-gray-500 disabled:text-corso-gray-200',
  primaryLight:
    'text-corso-blue-600 bg-corso-blue-50 ring-corso-blue-600 hover:bg-corso-blue-100 focus-visible:outline-corso-blue-600 disabled:bg-corso-gray-400 disabled:ring-corso-gray-500 disabled:text-corso-gray-200',
  success:
    'text-white bg-corso-green-700 ring-corso-green-800 hover:bg-corso-green-800 focus-visible:outline-corso-green-700 disabled:bg-corso-gray-400 disabled:ring-corso-gray-500 disabled:text-corso-gray-200',
  successLight:
    'text-corso-green-800 bg-corso-green-50 ring-corso-green-800 hover:bg-corso-green-100 focus-visible:outline-corso-green-800 disabled:bg-corso-gray-400 disabled:ring-corso-gray-500 disabled:text-corso-gray-200',
  danger:
    'text-white bg-corso-red-700 ring-corso-red-800 hover:bg-corso-red-800 focus-visible:outline-corso-red-700 disabled:bg-corso-gray-400 disabled:ring-corso-gray-500 disabled:text-corso-gray-200',
  dangerLight:
    'text-corso-red-700 bg-corso-red-50 ring-corso-red-700 hover:bg-corso-red-100 focus-visible:outline-corso-red-600 disabled:bg-corso-gray-400 disabled:ring-corso-gray-500 disabled:text-corso-gray-200',
  dangerOutline:
    'text-corso-red-700 bg-white ring-corso-red-800 hover:bg-corso-red-700 hover:text-white focus-visible:outline-corso-red-600 disabled:bg-corso-gray-400 disabled:ring-corso-gray-500 disabled:text-corso-gray-200',
  warning:
    'text-white bg-corso-orange-700 ring-corso-orange-700 hover:bg-corso-orange-700 focus-visible:outline-corso-orange-700 disabled:bg-corso-gray-400 disabled:ring-corso-gray-500 disabled:text-corso-gray-200',
} satisfies Record<ButtonVariant, string>;

const sizeStyles = {
  full: 'w-full',
  /** Intrinsic at larger screen sizes. */
  intrinsic: 'md:max-w-fit',
};

const baseStyle =
  'flex items-center justify-center gap-2 rounded-md px-3 py-2 text-center text-sm font-semibold shadow-sm ring-1 ring-inset focus:z-10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed';

type ButtonElementProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

/**
 * Abstraction of the native button element.
 * `className` can override all styles; however, it's intended primarily for overriding non-variant specific styling and box-model properties, i.e. margin and padding.
 * The default `type` has been changed to `button` instead of `submit` to prevent accidental form submission behavior.
 */
type ButtonProps = Omit<ButtonElementProps, 'type'> & {
  variant?: keyof typeof variantStyles;
  /** Defaults to dynamic sizing, if specified, it'll be `full` always or `intrinsic` after a breakpoint. */
  size?: keyof typeof sizeStyles;
  loading?: boolean;

  /**
   * Acts as type `button` by default, but when specified, acts as a `submit` type button.
   */
  type?: 'submit' | 'reset' | 'button';
};
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      onClick,
      variant = 'DEFAULT',
      size,
      loading = false,
      disabled = false,
      type = 'button',
      ...props
    },
    ref,
  ) => (
    <button
      // eslint-disable-next-line react/button-has-type -- always typed via props
      type={type}
      className={twMerge(
        variant !== 'text' && baseStyle,
        variantStyles[variant],
        size && sizeStyles[size],
        className,
      )}
      onClick={onClick}
      disabled={disabled || loading} // ? apply additional styles when disabled/loading
      {...props}
      ref={ref}
    >
      {loading && <SpinnerIcon />}
      {children}
    </button>
  ),
);
export default Button;

type LinkButtonProps = LinkProps & {
  children?: ReactNode;
  className?: string;
  variant?: ButtonVariant;
  size?: keyof typeof sizeStyles;
};

export function LinkButton({
  children,
  className,
  variant = 'DEFAULT',
  size,
  ...linkProps
}: LinkButtonProps) {
  return (
    <Link
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...linkProps}
      className={twMerge(
        baseStyle,
        variantStyles[variant],
        size && sizeStyles[size],
        className,
      )}
    >
      {children}
    </Link>
  );
}

type NavLinkButtonProps = NavLinkProps & {
  children?: ReactNode;
  variant?: ButtonVariant;
};

export function NavLinkButton({
  children,
  className,
  variant = 'DEFAULT',
  ...linkProps
}: NavLinkButtonProps) {
  return (
    <NavLink
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...linkProps}
      className={(navLinkRender) =>
        twMerge(
          baseStyle,
          variantStyles[variant],
          typeof className === 'function' ?
            className(navLinkRender)
          : className,
        )
      }
    >
      {children}
    </NavLink>
  );
}
