import { ReactNode } from 'react';
import DescriptionList from '~/components/DescriptionList';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import { StoreRule } from '~/types';
import {
  displayName,
  eventDisplay,
  StoreRuleExpressionDisplay,
} from './StoreRuleForm';

export function StoreRuleDisplay({
  storeRule,
  actions,
}: {
  storeRule: StoreRule;
  actions?: ReactNode;
}) {
  return (
    <Disclosure
      renderSummary={
        <SimpleSummary>
          <div className="flex w-full items-center justify-between">
            <span>{storeRule.name}</span>
            {actions}
          </div>
        </SimpleSummary>
      }
    >
      <div className="pl-4">
        <DescriptionList
          descriptions={[
            { term: 'Priority', details: storeRule.rule.priority },
            {
              term: 'Action',
              details: (
                <div className="mb-2">
                  <p className="mb-1">
                    {displayName.eventType[storeRule.rule.event.type]}
                  </p>
                  {eventDisplay[storeRule.rule.event.type](
                    // ! EXPLICIT TYPE ASSERTION // TODO figure out alternative so that we can remove `as never` assertions
                    storeRule.rule.event.params as never,
                  )}
                </div>
              ),
            },
            {
              term: 'Conditions',
              details: <StoreRuleExpressionDisplay rule={storeRule.rule} />,
            },
          ]}
        />
      </div>
    </Disclosure>
  );
}
