import { useQuery } from '@tanstack/react-query';
import { CrewMerchantUi } from 'corso-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import api from '~/api';
import Alert from '~/components/Alert';
import ProductImage from '~/components/ProductImage';
import Skeleton from '~/components/Skeleton';
import { Badge } from '~/components/ui/primitives/Badge';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useStoreId } from '~/hooks/useStoreId';

// ! MUCH OF THIS DUPLICATED FROM `exchange-options.ts` and `resolution-component.tsx` in `crew-customer-ui`

const productNotFoundImgUrl =
  'https://cdn.corso.com/img/image-not-available.jpeg';

type Product = CrewMerchantUi.VariantExchangeOptions[number];
type Variant = Product['variants'][number];

type ProductOption = Omit<NonNullable<Product['options'][number]>, 'values'> & {
  values: VariantOption[];
};

type VariantOption = {
  label: string;
  value: string;
  group: ProductOption['name'];
  available: boolean;
};

type State = {
  selectedProduct: Product;
  productOptions: ProductOption[];
  selectedVariant: Variant | undefined;
  selectedOptions: Record<ProductOption['name'], VariantOption>;
};

const createVariantOption = (group: string) => (value: string) =>
  ({
    value,
    label: value,
    group,
    available: true,
  }) satisfies VariantOption;

const createProductOption = (
  option: NonNullable<Product['options']>[number],
): ProductOption => ({
  ...option,
  values: option.values.map(createVariantOption(option.name)),
});

const formatVariantExchangeResponse = (
  res: CrewMerchantUi.VariantExchangeOptions,
): Product[] =>
  res.map(({ options, ...rest }) => ({
    ...rest,
    options: [...options].sort((a, b) => a.position - b.position),
  }));

const matchVariantByOption =
  ({ group, value }: VariantOption) =>
  (v: Variant) => {
    const option = v.options.find((o) => o.name === group);

    return option ? option.value === value : false;
  };

const checkAvailability = (variants: Variant[]) => (option: VariantOption) => ({
  ...option,
  available: variants.some(matchVariantByOption(option)),
});

const updateOptionAvailability =
  (variants: Variant[], selected: VariantOption) =>
  ({ values, ...option }: ProductOption) =>
    ({
      ...option,
      values:
        // don't update the availability of the option group that changed e.g. if color:black is selected, don't update color options
        option.name === selected.group ?
          values
          // e.g if color:black is selected, only show the sizes for black as available
        : values.map(
            checkAvailability(
              // only need to check the variants that match the selected option e.g. if color:black is selected, only check the black variants
              variants.filter(matchVariantByOption(selected)),
            ),
          ),
    }) satisfies ProductOption;

const initializeSelectedOptions = (
  productOptions: ProductOption[],
  selectedVariantOptions: Variant['options'],
) =>
  Object.fromEntries(
    selectedVariantOptions.map(({ name: optionName, value }) => {
      const productOption = productOptions.find(
        ({ name }) => name === optionName,
      );

      const selectedOption = productOption?.values.find(
        (option) => option.value === value,
      );

      if (selectedOption === undefined) {
        throw new Error('Selected option not found');
      }

      return [optionName, selectedOption];
    }),
  ) satisfies State['selectedOptions'];

const simulateChangeVariantOption = (
  productOptions: ProductOption[],
  selectedOptions: State['selectedOptions'],
) => {
  const firstProductOption = productOptions[0];
  if (firstProductOption === undefined) {
    throw new Error('No options for selected product');
  }
  const simulatedChangedVariantOption =
    selectedOptions[firstProductOption.name];
  if (simulatedChangedVariantOption === undefined) {
    throw new Error('No options for selected product');
  }

  return simulatedChangedVariantOption;
};

const changeProduct = (selectedProduct: Product): State => {
  const { variants, options } = selectedProduct;
  const productOptions = options.map(createProductOption);
  const firstVariant = variants[0];

  if (firstVariant === undefined) {
    // again, this shouldn't happen because the products should have variants
    throw new Error('No variants for selected product');
  }

  const selectedOptions = initializeSelectedOptions(
    productOptions,
    firstVariant.options,
  );

  return {
    selectedProduct,
    selectedVariant: firstVariant,
    selectedOptions,
    productOptions: productOptions.map(
      updateOptionAvailability(
        variants,
        simulateChangeVariantOption(productOptions, selectedOptions),
      ),
    ),
  };
};

const getSelectVariant = (
  variants: Variant[],
  selectedOptions: VariantOption[],
) =>
  variants.find((variant) =>
    selectedOptions.every((option) => matchVariantByOption(option)(variant)),
  );

const changeVariantOption = (
  state: State,
  selectedOption: VariantOption,
): State => {
  const { variants } = state.selectedProduct;

  const updatedSelectedOptions = {
    ...state.selectedOptions,
    [selectedOption.group]: selectedOption,
  };

  return {
    ...state,
    selectedOptions: updatedSelectedOptions,
    selectedVariant: getSelectVariant(
      variants,
      Object.values(updatedSelectedOptions),
    ),
    productOptions: state.productOptions.map(
      updateOptionAvailability(variants, selectedOption),
    ),
  };
};
const useExchangeProductData = (products: Product[]) => {
  const initialSate = useMemo(() => {
    const initialProduct = products[0];

    if (initialProduct === undefined) {
      throw new Error('No products in exchange data');
    }

    return changeProduct(initialProduct);
  }, [products]);

  const [state, setState] = useState<State>(initialSate);

  const onProductChange = useCallback(
    (productId: Product['idFromPlatform']) => {
      const product = products.find(
        ({ idFromPlatform }) => idFromPlatform === productId,
      );
      if (product === undefined) {
        throw new Error('No product found');
      }

      setState(changeProduct(product));
    },
    [products],
  );

  const onVariantOptionChange = useCallback(
    (optionName: ProductOption['name']) =>
      (selectedValue: VariantOption['value']) => {
        /**
         * This is a workaround to prevent the error that occurs when the
         * select value is empty that happens when the product select changes
         * and the new initial selected product options is not same as the previous
         * product's initial selected options.
         */
        if (!selectedValue) return;

        const productOption = state.productOptions.find(
          ({ name }) => name === optionName,
        );
        const variantOption = productOption?.values.find(
          ({ value }) => value === selectedValue,
        );

        if (variantOption === undefined) {
          throw new Error('No variant option found');
        }

        setState((previousState) =>
          changeVariantOption(previousState, variantOption),
        );
      },
    [state.productOptions],
  );

  return {
    ...state,
    products,
    onProductChange,
    onVariantOptionChange,
  };
};

function useVariantExchangeOptions(idFromPlatform: string) {
  const storeId = useStoreId();
  return useQuery({
    queryKey: ['variantExchangeOptions', storeId, idFromPlatform],
    queryFn: () => api.store(storeId).variantExchangeOptions(idFromPlatform),
    select: formatVariantExchangeResponse,
  });
}

function VariantSelectOption({ option }: { option: VariantOption }) {
  return (
    <div className={twMerge('flex flex-wrap items-baseline gap-2')}>
      <span className={twMerge(!option.available && 'line-through')}>
        {option.label}
      </span>
      {!option.available && <Badge>Unavailable</Badge>}
    </div>
  );
}

function ProductSelectOption({ option }: { option: Product }) {
  return (
    <div className="flex w-full items-center gap-2">
      <img
        src={option.imageUrl ?? productNotFoundImgUrl}
        aria-hidden
        className="h-6 w-6 flex-shrink-0 rounded-full"
        alt="Image for product option"
      />
      <span>{option.name}</span>
    </div>
  );
}

function VariantExchange({
  exchangeProducts,
  onChange,
}: {
  exchangeProducts: Product[];
  onChange: (selectedVariant: Variant) => void;
}) {
  const { onProductChange, onVariantOptionChange, ...state } =
    useExchangeProductData(exchangeProducts);

  useEffect(() => {
    if (!state.selectedVariant) return;
    onChange(state.selectedVariant);
  }, [state.selectedVariant, onChange]);

  return (
    <div className="flex flex-col items-center gap-4 md:flex-row">
      <ProductImage
        src={(state.selectedVariant ?? state.selectedProduct).imageUrl}
        alt={state.selectedVariant?.name ?? state.selectedProduct.name}
        className="size-32 rounded-lg"
      />
      <div className="flex-grow space-y-2">
        <SimpleSelect
          label="Product"
          options={state.products.map((option) => ({
            label: <ProductSelectOption option={option} />,
            value: option.idFromPlatform,
          }))}
          value={state.selectedProduct.idFromPlatform}
          onChange={onProductChange}
        />

        {!state.selectedProduct.hasOnlyDefaultVariant &&
          state.productOptions.map(({ name, values }) => (
            <SimpleSelect
              key={name}
              label={name}
              options={values.map((option) => ({
                label: <VariantSelectOption option={option} />,
                value: option.value,
              }))}
              value={state.selectedOptions[name]?.value} // although nullish, it really should always exist, if not, this will change from controlled/uncontrolled or vice versa
              onChange={onVariantOptionChange(name)}
            />
          ))}
      </div>
    </div>
  );
}

export function ClaimCreateVariantExchange({
  idFromPlatform,
  selectedQuantity,
  onChange,
}: {
  /** `product.idFromPlatform` to query available exchange options. */
  idFromPlatform: string;
  /** The quantity of the line item being exchanged. */
  selectedQuantity: number;
  onChange: (
    variant: NonNullable<
      CrewMerchantUi.ClaimCreate['claimLineItems'][number]['variantExchangeLineItem']
    >,
  ) => void;
}) {
  const { isLoading, isError, error, data } =
    useVariantExchangeOptions(idFromPlatform);

  const callback = useCallback(
    (selectedVariant: Variant) =>
      onChange({
        idFromPlatform: selectedVariant.idFromPlatform,
        quantity: selectedQuantity,
      }),
    [onChange, selectedQuantity],
  );

  // TODO improve loading/error/data (zero, one, many) states
  if (isError) return <Alert message={error.message} />; // TODO review error messages
  if (isLoading || !data)
    return <Skeleton.Rectangle height="2rem" width="100%" />;

  if (!data.length) {
    return (
      <Alert message="No product options found. Select another resolution." />
    );
  }

  return <VariantExchange exchangeProducts={data} onChange={callback} />;
}
